import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";

import "../../../assets/styles/welcome.css";

import logo from "../../../assets/images/SkipBinCo-Logo.webp";

import {postUnauthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const Welcome = () => {
    const M = window.M;

    let { request_hash } = useParams();
    const navigate = useNavigate();
    const userProfile = useSelector(state => state.userProfile);

    const [view, setView] = useState('decision');
    const [request, setRequest] = useState({});

    useEffect(() => {
        if(request_hash){
            getRequestByHash();
        }
    }, [request_hash]);

    useEffect(() => {
        if(view === "intro") {
            let introTitle = document.getElementById('intro-title')
            if(introTitle) {
                introTitle.scrollIntoView();
            }
        }
        if(view === "form") {
            let formTitle = document.getElementById('form-title')
            if(formTitle) {
                formTitle.scrollIntoView();
            }
        }
    }, [view])

    function getRequestByHash() {
        let url = API_ENDPOINT + "v1/Requests/getByHash";
        let params = {
            hash: request_hash
        };

        postUnauthenticated(url, params).then(results => {
            setRequest(results.data);
        });
    }

    function handleCustomerDecisions(decision) {
        if(decision === "new") {
            setView("intro");
        }
        if(decision === "existing") {
            if(request && request.id) {
                navigate('/enquiry', {state: { request: request }})
            }else{
                userProfile ? navigate('/listings') : navigate('/login');
            }
        }
    }

    return (
        <div>
            <div className="logo-center text-center align-middle fs-72 fw-bold py-3 h-100" style={{minHeight: "100vh"}}>
                <div className="w-100 m-auto text-center">
                    <img src={logo} alt='logo' className="col-md-4 col-9 text-center m-auto" />
                </div>

                <div className="lead fs-22 text-theme darkblue fw-semibold text-capitalize pt-4 pb-5">
                    <div>Australia’s only skip bin auction site</div>
                    <div>get the best price guaranteed</div>
                </div>

                <div className="d-lg-flex d-md-block w-75 text-center" style={{marginTop: "0rem"}}>
                    <div role="button" className="col-lg-5 col-md-12 p-4 bg-theme yellow rounded-1 m-auto" onClick={() => handleCustomerDecisions("new")}>
                        <div className="fs-26 fw-semibold">New Customer? Click here</div>
                    </div>

                    <div role="button" className="col-lg-5 col-md-12  p-4 bg-theme blue rounded-1 m-auto" onClick={() => handleCustomerDecisions("existing")}>
                        <div className="text-white fs-26 fw-semibold">Returning Customer? Click here</div>
                    </div>
                </div>

                {userProfile && userProfile.id && request && request.id ?
                    <div className="fs-26 fw-semibold text-capitalize mt-5">
                        <Link to={'/listings'} className="link-primary text-decoration-underline font-sans">Or view listings</Link>
                    </div>
                :null}
            </div>

            {view === "intro" &&
                <div className="col-lg-9 col-11 m-auto mt-5 font-theme shadow shadow-md p-4">
                    <div className="mb-5 m-auto">
                        <h3 id="intro-title" className="lead fs-38 fw-bold text-uppercase mb-2 heading-theme">How to get a cheaper price on your skip bin hire?</h3>
                        <p className="text-secondary-emphasis fs-18 mt-1 m-auto paragraph-theme">
                            Skip Bin Co is the first skip bin site in Australia that allows you to get cheaper prices
                            for your bin than what you might be able to find elsewhere. We are able to achieve
                            this through our auction site which allows a network of hundreds of skip bin
                            operators in every state to bid on a skip bin job and compete against each other. The
                            site does the shopping around for you, saving you time and money.
                            <br /><br />
                            If you elect to get a cheaper price, only your suburb, bin size and bin type is posted.
                            Within 24 hours, bin operators in your area can place 2 bids. At the end of the 24
                            hour auction, you can select the cheapest bin operator with the best rating out of 5
                            stars. Often prices can be up to 20-30% cheaper than normal market rates.
                        </p>
                    </div>

                    <div className="">
                        <video width="100%" height="240" controls>
                            <source src="https://www.youtube.com/watch?v=rzojwGMdiw8&ab_channel=SkillCappedChallengerLoLGuides" />
                        </video>
                    </div>

                    <div className="m-auto mt-5">
                        <h3 className="lead fs-38 fw-bold text-uppercase text-start heading-theme">Why are the skip bin operators able to price it cheaper than the market:</h3>
                        <p className="lead m-auto mt-2 fs-18 paragraph-theme">
                            <span className="fw-bold">Location:</span>
                            <div>A skip bin operator might be based in the area close to where your bin will
                            be delivered to, so their labour costs for travel, petrol and tolls will be much cheaper
                            than someone who might be based an hour away.</div>
                        </p>

                        <p className="lead m-auto mt-2 fs-18">
                            <span className="fw-bold">Existing jobs in the area:</span>
                            <div>the bin company might already have a job going to the
                                same area or be passing your area on the way, so again their costs are reduced.</div>
                        </p>

                        <p className="lead m-auto mt-2 fs-18">
                            <span className="fw-bold">Lack of work:</span>
                            <div>
                                the skip bin industry is dominated by some major players. Smaller to
                                medium operators can often have gaps in their day were they need work to fill gaps
                                or drivers are sitting around a yard waiting. They might do for cost price to keep their
                                team moving.
                            </div>
                        </p>

                        <p className="lead m-auto mt-2 fs-18">
                            <span className="fw-bold">Looking to build their brand:</span>
                            <div>
                                there are many operators that are motivated to grow.
                                They will often take on jobs cheaper to ensure their trucks and bins are on the road
                                more often making them more visible and creating better brand awareness.
                            </div>
                        </p>
                    </div>

                    <div className="mt-5 pb-2">
                        {(request_hash && request && request.id ) ?
                            <div className="p-3 w-100">
                                <button className="btn-theme blue w-100 lead fs-16 px-4 py-3 text-center" onClick={() => navigate('/enquiry', {state: { request: request }})}>
                                    <div className="d-flex justify-content-center">
                                        <div className="bi bi-send me-2 align-self-center fs-20" />
                                        <span className="text-capitalize fs-24">Place your enquiry</span>
                                    </div>
                                </button>
                            </div>
                            :
                            <div role="button" className="bg-theme yellow lead fs-24 fw-normal text-center p-3 rounded-2" onClick={() => userProfile ? navigate('/listings') : navigate('/signup')}>Want to try us out? Click here to begin creating your account</div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default Welcome;