import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {DateTime} from "luxon";

import Loader from "../../common/Loader";
import JobBids from "../../modals/JobBids";
import TextInput from "../../form/TextInput";
import {setModal} from "../../../utils/slices/modal.slice";
import {setToast} from "../../../utils/slices/toasts.slice";
import {setBanner} from "../../../utils/slices/banner.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {JOB_DETAILS_STRUCTURE} from "../../../utils/structures/job.structures";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {CONTRACTOR_ROLE_ID, CUSTOMER_ROLE_ID} from "../../../utils/constants/roles.constants";

const Job = () => {
    const { hash } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const banner = useSelector(state => state.banner);
    const toasts = useSelector(state => state.toasts);
    const userProfile = useSelector(state => state.userProfile);

    const [init, setInit] = useState(false);
    const [hasConfirmedBid, setHasConfirmedBid] = useState(false);
    const [hasRemainingTime, setHasRemainingTime] = useState(false);

    const [job, setJob] = useState({});
    const [errors, setErrors] = useState({});
    const [userBids, setUserBids] = useState({});

    const [amount, setAmount] = useState(0);
    const [remainingTime, setRemainingTime] = useState("");

    useEffect(() => {
        if(init) {
            document.title = `${job.request.content.name} - ${job.request.content.waste_type}`
            calculateRemainingTime();
        }
    }, [init]);

    useEffect(() => {
        if(hash) {
            getJobByHash();
        }else{
            navigate("/jobs")
        }
    }, [hash]);

    useEffect(() => {
        if(job && job.id) {
            const interval = setInterval(() => calculateRemainingTime(), 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    function getJobByHash() {
        let url = API_ENDPOINT + "v1/Jobs/getJobByHash";
        let params = {
            hash: hash
        };

        postAuthenticated(url, params).then(results => {
            setJob(results.data);

            let user_bids = (results.data).bids.filter(x => x.contractor_id == userProfile.id);
            if(user_bids.length === 1) {
                setUserBids({
                    'initial': user_bids[0]
                });
            }else if(user_bids.length > 1){
                setUserBids({
                    'initial': user_bids[1],
                    'counter': user_bids[0]
                });
            }

            setInit(true);

            dispatch(setBanner({
                ...banner,
                label: "Job Details"
            }));

        });
    }

    function createBid() {
        let url = API_ENDPOINT + "v1/Bids/createBid";
        let params = {
            amount: amount,
            job_id: job.id,
        }

        postAuthenticated(url, params).then(results => {
            getJobByHash();
            setAmount(0)
            setHasConfirmedBid(false);

            let toast = {
                ...toasts,
                [toasts.length]: {
                    head: "Notification",
                    body: <span className="text-success">Bid was successfully placed</span>,
                }
            };
            dispatch(setToast(Object.assign([], toast)));
        });
    }

    function calculateRemainingTime() {
        let auction, active;
        let date_delivery = DateTime.fromFormat(job.request.content.delivery_date, "MMMM dd, yyyy");
        let created_timestamp = DateTime.fromSQL(job.created);
        let difference = created_timestamp.diff(date_delivery);

        // Within 20hrs, 12hr auction
        if(difference.toFormat('hh') <= 20) {
            auction = created_timestamp.plus({ hours: 12 });
        }

        // Greater than 20hrs, 20hr auction
        if(difference.toFormat('hh') > 20) {
            auction = created_timestamp.plus({ hours: 20 });
        }

        let auction_diff = auction.diffNow();
        if(auction_diff.values.milliseconds > -1) {
            setRemainingTime(auction_diff.toFormat("hh'h' mm'm' s's'"));
            active = true;
        }else{
            setRemainingTime("0hrs 0mins");
            active = false;
        }

        setHasRemainingTime(active);
    }

    function validateBidAmount() {
        setHasConfirmedBid(true);

        let _errors = {};
        let isValid = true;

        const int_regex = /^[0-9]*$/;
        if(!int_regex.test(amount)) {
            isValid = false;
            _errors['amount'] = "Invalid input";
        }

        if(amount < 1) {
            isValid = false;
            _errors['amount'] = "Amount needs to be greater than zero";
        }

        if(job.bids.length > 0) {
            let highest_amount = job.bids.reduce((prev, current) => (prev && prev.content.amount > current.content.amount) ? prev : current).content.amount;
            if(parseFloat(amount) <= parseFloat(highest_amount)) {
                isValid = false;
                _errors['amount'] = `Amount needs to be greater than $${highest_amount}`;
            }
        }
        setErrors(_errors);

        isValid ? createBid() : setHasConfirmedBid(false);
    }

    function determineStatus() {
        if(job.winning_bid_id != null) {
            return <span className="text-danger">Closed</span>
        }else{
            return hasRemainingTime ? <span className="text-primary">Open</span> : <span className="text-warning">Pending</span>;
        }
    }

    function launchBids() {
        let modalSettings = {
            config: {
                component: JobBids,
                props: {
                    job: job,
                    bids: job.bids
                }
            },
            onClose: () => {}
        }
        dispatch(setModal(modalSettings));
    }

    function getAveragePriceRange() {
        let price = (parseFloat(job.request.content.grand_total)+100).toFixed(2);
        let high_range = price + (price * 0.1);
        let low_range = price - (price * 0.1);

        return `${low_range.toFixed(0)} - $${parseFloat(high_range).toFixed(0)}`;
    }

    return (
        init ?
            <div className="mt-5 col-lg-9 col-11 m-auto">
                <div className="w-100 m-auto mt-3">
                    <div className="d-lg-flex d-md-block m-auto p-4 pt-0 px-0">
                        <div className="col-lg-6 col-md-12 text-center border border-1 py-4 mt-0">
                            <img className="m-auto" src={job.request.content.image_url} width="80%" height="90%" alt={`${job.request.content.name} image`} />
                        </div>

                        <div className="col-lg-6 col-12 mt-3 mt-md-0">
                            <div className="m-auto lead ps-0 ps-md-3">
                                <h2 className="fw-normal mb-0 pb-0">{job.request.content.name} - {job.request.content.waste_type}</h2>
                                <div className="p-0 m-0 fs-16">{determineStatus()}</div>

                                <div className="py-4 pb-3 fs-16">
                                    <i className="bi bi-clock pe-1" />
                                    <span className="fw-normal">Time left:</span> {job.winning_bid_id != null ? '0hrs 0mins' : remainingTime}
                                </div>

                                <div className="d-flex">
                                    <div className="col-6">
                                        <div className="pt-2 fs-16">Current Bid <span role="button" className="link-primary" onClick={() => launchBids()}>({job.bids.length} bids):</span>
                                            <span className="ps-1 fw-bold">${job.bids.length > 0 ? job.bids.reduce((prev, current) => (prev && prev.content.amount > current.content.amount) ? prev : current).content.amount : 0}</span>
                                        </div>

                                        {userProfile && userProfile.role_id != CUSTOMER_ROLE_ID ?
                                            <div className="pt-2 fs-16">Average price: <span className="fw-semibold">~${getAveragePriceRange()}</span></div>
                                        :null}
                                    </div>
                                </div>

                                {userProfile && userProfile.role_id == CONTRACTOR_ROLE_ID &&
                                    userProfile.status_id != 1 ? <div className="mt-3 fst-italic fw-bold fs-16 text-danger">You must be verified before you can bid</div> :
                                    <nav className="mt-4">
                                        <div className="nav nav-tabs col-lg-6 col-md-12" id="nav-tab" role="tablist">
                                            <button className="nav-link active fs-14" id="nav-home-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                                                    aria-selected="true">Initial Offer
                                            </button>
                                            <button className="nav-link fs-14" id="nav-profile-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-profile" type="button" role="tab"
                                                    aria-controls="nav-profile" aria-selected="false" disabled={!userBids.initial}>Counter Offer
                                            </button>
                                        </div>

                                        <div className="tab-content w-100" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <React.Fragment>
                                                    <div className="d-flex align-middle">
                                                        {/* Initial Bid */}
                                                        <div className={`${job.winning_bid_id != null || !hasRemainingTime || userBids.initial ? 'col-12' : 'col-6'}`}>
                                                            <TextInput
                                                                name="price"
                                                                value={userBids.initial ? userBids.initial.content.amount : amount}
                                                                onChange={(e) => setAmount(e.target.value)}
                                                                className={`w-100 rounded-0 ${!userBids.initial && errors.amount ? 'border border-danger' : ''}`}
                                                                disabled={hasConfirmedBid || userBids.initial || job.winning_bid_id != null || !hasRemainingTime}
                                                            />
                                                        </div>

                                                        {job.winning_bid_id != null || !hasRemainingTime || userBids.initial ? null :
                                                            <button className="btn-theme green p-0 px-4 rounded-0 fs-14" onClick={() => validateBidAmount()} disabled={hasConfirmedBid || job.winning_bid_id != null}>
                                                                {hasConfirmedBid ? <Loader /> : 'Confirm bid'}
                                                            </button>
                                                        }
                                                    </div>
                                                    {errors.amount && <div className={`lead text-danger fs-14 mt-1 mb-2`}>{errors.amount}</div>}
                                                </React.Fragment>
                                            </div>

                                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                {userBids.initial ?
                                                    <React.Fragment>
                                                        <div className="d-flex align-middle">
                                                            {/* Counter Bid */}
                                                            <div className={`${job.winning_bid_id != null || !hasRemainingTime || userBids.counter ? 'col-12' : 'col-6'}`}>
                                                                <TextInput
                                                                    name="price"
                                                                    value={userBids.counter ? userBids.counter.content.amount : amount}
                                                                    placeholder={`Enter amount greater than ${job.bids.reduce((prev, current) => (prev && parseFloat(prev.content.amount) < parseFloat(prev.content.amount)) ? prev : current).content.amount}`}
                                                                    onChange={(e) => setAmount(e.target.value)}
                                                                    className={`rounded-0 ${errors.amount ? 'border border-danger' : ''}`}
                                                                    disabled={hasConfirmedBid || userBids.counter || job.winning_bid_id != null || !hasRemainingTime}
                                                                />
                                                            </div>

                                                            {job.winning_bid_id != null || !hasRemainingTime || userBids.counter ? null :
                                                                <button className="btn-theme green p-0 px-4 rounded-0 fs-14" onClick={() => validateBidAmount()} disabled={hasConfirmedBid || userBids.counter || job.winning_bid_id != null}>
                                                                    {hasConfirmedBid ? <Loader /> : 'Confirm bid'}
                                                                </button>
                                                            }
                                                        </div>
                                                        {errors.amount && <div className={`lead text-danger fs-14 mt-1 mb-2`}>{errors.amount}</div>}
                                                    </React.Fragment>
                                                : <div className="fs-14 lead mt-1 text-secondary">Initial bid is required</div> }
                                            </div>
                                        </div>
                                    </nav>
                                }

                                <div className="mt-4">
                                    <div className="d-lg-flex d-md-block">
                                        <div className="col-lg-6 d-md-block">
                                            {JOB_DETAILS_STRUCTURE.map((detail, index) =>
                                                detail.constant === "bin_details" &&
                                                <React.Fragment key={index}>
                                                    <span className="fs-16">{detail.label}</span>
                                                    <hr className="m-0 p-0 mb-2 w-100" />

                                                    <div className="mb-4">
                                                        {detail.properties.map((property, property_index) =>
                                                            <div key={property_index} className="text-secondary fs-14 mb-1">
                                                                <i className={`${property.icon} pe-1 text-dark`} />
                                                                <span className="fw-bold">{property.label}:</span> {job.request.content[property.constant]}
                                                            </div>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            {JOB_DETAILS_STRUCTURE.map((detail, index) =>
                                                detail.constant === "additional_items" &&
                                                <React.Fragment key={index}>
                                                    <span className="fs-16">{detail.label}</span>
                                                    <hr className="m-0 p-0 mb-2 w-100" />

                                                    <div className="mb-4">
                                                        {detail.properties.map((property, property_index) =>
                                                            <div key={property_index} className="text-secondary fs-14 mb-1">
                                                                <i className={`${property.icon} pe-1 text-dark`} />
                                                                <span className="fw-bold">{property.label}:</span> {job.request.content.additional_items[property.constant] != ""
                                                                    ? ` ${job.request.content.additional_items[property.constant]}`
                                                                    : ' $0'
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-lg-flex d-md-block">
                                    <div className="col-lg-6 col-md-12">
                                        {JOB_DETAILS_STRUCTURE.map((detail, index) =>
                                            detail.constant === "location_details" &&
                                            <React.Fragment key={index}>
                                                <span className="fs-16">{detail.label}</span>
                                                <hr className="m-0 p-0 mb-2 w-95" />

                                                <div className="mb-4">
                                                    {detail.properties.map((property, property_index) =>
                                                        <div key={property_index} className="text-secondary fs-14 mb-1">
                                                            <i className={`${property.icon} pe-1 text-dark`} />
                                                            <span className="fw-bold">{property.label}:</span> {job.content[property.constant]}
                                                        </div>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        {JOB_DETAILS_STRUCTURE.map((detail, index) =>
                                            detail.constant === "delivery_details" &&
                                            <React.Fragment key={index}>
                                                <span className="fs-16">{detail.label}</span>
                                                <hr className="m-0 p-0 mb-2 w-100" />

                                                <div className="mb-4">
                                                    {detail.properties.map((property, property_index) =>
                                                        <div key={property_index} className="text-secondary fs-14 mb-1">
                                                            <i className={`${property.icon} pe-1 text-dark`} />
                                                            <span className="fw-bold">{property.label}:</span> {job.request.content[property.constant]}
                                                        </div>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <Loader />
    );
};

export default Job;