import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DateTime} from "luxon";

import CheckoutForm from "../../../modals/CheckoutForm";

import {setModal} from "../../../../utils/slices/modal.slice";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";
import Loader from "../../../common/Loader";

const JobsAndBids = (props) => {
    const userProfile = useSelector(state => state.userProfile);
    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const [jobs, setJobs] = useState(props.jobs);

    useEffect(() => {
        if(!props.orders) {
            getJobs();
        }else{
            setJobs(props.orders);
            setInit(true);
        }
    }, [props])

    function getJobs() {
        let url = API_ENDPOINT + "v1/Jobs/getJobs";
        let params = {
            keywords: "",
            filters: []
        };

        postAuthenticated(url, params).then(results => {
            setJobs(results.data);
            setInit(true);
        });
    }

    function selectWinningBid(bid, job, payment_id) {
        let url = API_ENDPOINT + "v1/Jobs/selectWinningBid";
        let params = {
            job_id: job.id,
            bid_id: bid.id,
            payment_id: payment_id
        }

        postAuthenticated(url, params).then(results => {
            props.getCustomerJobs();
        });
    }

    function openRow(job, index) {
        setJobs(Object.assign([], {
            ...jobs,
            [index]: {
                ...jobs[index],
                is_open: !job.is_open
            }
        }));
    }

    function hasWinningBid(job) {
        return job.winning_bid_id != null;
    }

    function isWinningBid(job, bid) {
        if(job.winning_bid) {
            return job.winning_bid_id == bid.id;
        }else{
            return false;
        }
    }

    function launchCheckoutForm(bid, job) {
        let modalSettings = {
            config: {
                component: CheckoutForm,
                props: {
                    bid: bid,
                    job: job,
                    selectWinningBid: selectWinningBid
                }
            },
            onClose: () => {}
        }
        dispatch(setModal(modalSettings));
    }

    return (
        init ?
            <div className="mt-2">
                {jobs.length === 0 ? <div className="lead fs-16 text-secondary">No orders were found.</div> :
                    <React.Fragment>
                        <div className="fs-14 text-secondary mb-1">Results: {jobs.length}</div>
                        {jobs.map((job, index) =>
                            <div key={index} className="border border-1 mb-3 rounded">
                                <div role="button" className="d-flex px-3 py-2 pt-0" onClick={() => openRow(job, index)}>
                                    <div className="align-self-center">
                                        <i className={`me-3 ${job.is_open ? 'fa fa-angle-down' : 'fa fa-angle-right'} m-0`} />
                                    </div>
                                    <div className="col-6">
                                        <span className={`p-0 m-0 fs-12 ${hasWinningBid(job) ? 'text-success' : 'text-primary'}`}>{hasWinningBid(job) ? 'Complete' : 'Open'}</span>
                                        <h3 className="fs-18 m-0 align-self-center fw-normal">
                                            {job.request.content.name}</h3>
                                        <span className="text-secondary fs-14">Bids: {Object.keys(job.bids).length}</span>
                                    </div>
                                </div>

                                {job.is_open &&
                                    <div className="border border-1 border-start-0 border-end-0 border-bottom-0 p-2">
                                        {job.bids.length === 0 ? <div className="text-secondary lead ms-3 fs-18">No bids have been made</div> :
                                            <table className="table fs-14 m-auto mb-3 w-95">
                                                <thead>
                                                    <tr>
                                                        <th className="fw-bold">Contractor</th>
                                                        <th className="fw-bold">Amount</th>
                                                        <th className="fw-bold">Date</th>
                                                        <th className="fw-bold">Time</th>
                                                        <th className="fw-bold">Rating</th>
                                                        <th className="fw-bold text-end" />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {job.winning_bid &&
                                                        <tr className={`p-2 align-middle border border-1 border-top-0 border-start-0 border-end-0 bg-dark text-white`}>
                                                            <td>
                                                                <i className="fa fa-trophy pe-1 text-warning" />
                                                                {job.winning_bid.contractor.content.company_name}
                                                            </td>
                                                            <td>${job.winning_bid.content.amount}</td>
                                                            <td>{DateTime.fromSQL(job.winning_bid.created).toFormat('dd/MM/yyyy')}</td>
                                                            <td>{DateTime.fromSQL(job.winning_bid.created).toFormat('hh:mm a')}</td>
                                                            <td>
                                                                {[1,2,3,4,5].map(index =>
                                                                    index < job.review.rating
                                                                        ? <span className="fa fa-star checked text-warning"></span>
                                                                        : <span className="fa fa-star text-light-emphasis"></span>
                                                                )}
                                                            </td>
                                                            <td className="text-start ps-2">
                                                                <span className="fst-italic text-success lead fs-14 fw-bold">Winner</span>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {job.bids.map((bid, bid_index) =>
                                                        job.winning_bid_id != bid.id &&
                                                        <tr className={`p-2 align-middle border border-1 border-top-0 border-start-0 border-end-0 ${isWinningBid(job, bid) ? 'bg-dark text-white' : ''}`}>
                                                            <td>
                                                                {isWinningBid(job, bid) && <i className="fa fa-trophy pe-1 text-warning" /> }
                                                                {bid.contractor.content.company_name}
                                                            </td>
                                                            <td>${bid.content.amount}</td>
                                                            <td>{DateTime.fromSQL(bid.created).toFormat('dd/MM/yyyy')}</td>
                                                            <td>{DateTime.fromSQL(bid.created).toFormat('hh:mm a')}</td>
                                                            <td>
                                                                {[1,2,3,4,5].map(index =>
                                                                    index < job.review.rating
                                                                        ? <span className="fa fa-star checked text-warning"></span>
                                                                        : <span className="fa fa-star text-light-emphasis"></span>
                                                                )}
                                                            </td>
                                                            <td className="text-start ps-2">
                                                                {!hasWinningBid(job) && <button className="btn-theme darkblue" onClick={() => launchCheckoutForm(bid, job)}>Select as winner</button> }
                                                                {isWinningBid(job, bid) && <span className="fst-italic text-success lead fs-14 fw-bold">Winner</span> }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                }
                            </div>
                        )}
                    </React.Fragment>
                }
            </div>
        : <Loader />
    );
};

export default JobsAndBids;