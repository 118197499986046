import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import "./../../../assets/styles/onboarding.css";

import TextInput from "../../form/TextInput";
import {setNavigation} from "../../../utils/slices/navigation.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setUserProfile} from "../../../utils/slices/userProfile.slice";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {CUSTOMER_ROLE_ID} from "../../../utils/constants/roles.constants";

const Onboarding = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigation = useSelector(state => state.navigation);
    const userProfile = useSelector(state => state.userProfile);
    const [currentPage, setCurrentPage] = useState(1);
    const pages = [
        { constant: "welcome", index: 1},
        { constant: "profile_details", index: 2},
        { constant: "notification_settings", index: 3},
        { constant: "vetting_details", index: 4, permission: "userProfile.role_id == 2"},
        { constant: "all_done", index: 5},
    ];

    useEffect(() => {
        let nav = {
            ...navigation,
            hide: true
        };
        dispatch(setNavigation(nav));
    }, []);

    useEffect(() => {
        return () => {
            let nav = {
                ...navigation,
                hide: false
            };
            dispatch(setNavigation(nav));
        }
    }, []);

    function updateUser(profile) {
        let url = API_ENDPOINT + "v1/Users/updateUser";
        let params = {
            user: profile
        }

        postAuthenticated(url, params).then(results => {

        }).catch(error => alert(error))
    }

    function handleChange(event) {
        let profile = {
            ...userProfile,
            content: {
                ...userProfile.content,
                [event.target.name]: event.target.value
            }
        }

        dispatch(setUserProfile(profile));
        updateUser(profile);
    }

    function toggleNotifications(event) {
        let name = event.target.name;
        let value = !userProfile.content.notifications[name];

        let profile = {
            ...userProfile,
            content: {
                ...userProfile.content,
                notifications: {
                    ...userProfile.content.notifications,
                    [name]: value
                }
            }
        }

        dispatch(setUserProfile(profile));
        updateUser(profile);
    }

    function toggleReceiveNotifications(field, type, event) {
        let name = event.target.name;
        let value = type === 'string' ? event.target.value : !userProfile.content.notifications[field][name];

        let profile = {
            ...userProfile,
            content: {
                ...userProfile.content,
                notifications: {
                    ...userProfile.content.notifications,
                    [field]: {
                        ...userProfile.content.notifications[field],
                        [name]: value
                    }
                }
            }
        }

        dispatch(setUserProfile(profile));
        updateUser(profile);
    }

    function handlePage() {
        if(currentPage == 3){
            if(userProfile.role_id == CUSTOMER_ROLE_ID) {
                setCurrentPage(currentPage + 1);
            }else{
                setCurrentPage(currentPage + 2);
            }
        }else{
            setCurrentPage(currentPage + 1);
        }
    }

    function renderButton(page) {
        if(Object.hasOwn(page, 'permission')) {
            return !eval(page.permission)
                ? null
                : <i role="button" className={`bi ${currentPage === page.index ? 'bi-circle-fill' : 'bi-circle'} px-1`} onClick={() => setCurrentPage(page.index)} />
        }

        return <i role="button" className={`bi ${currentPage === page.index ? 'bi-circle-fill' : 'bi-circle'} px-1`} onClick={() => setCurrentPage(page.index)} />
    }

    return (
        <div className="border border-1 rounded w-90 m-auto mt-4 p-4 shadow vh-90">
            {currentPage === 1 &&
                <div className="onboarding h-100 w-100 overflow-auto">
                    <h2 className="text-center fs-32">Welcome! Let's get you started</h2>
                    <div className="text-center">
                        <i role="button" className="bi bi-arrow-right-circle link-primary text-dark fs-1" onClick={() => handlePage()} />
                    </div>
                </div>
            }

            {currentPage === 2 &&
                <div className="h-100 w-75 m-auto overflow-auto">
                    <h2 className="fs-26 mb-1">Profile Details</h2>
                    <hr className="m-0 mb-4" />

                    <div>
                        <div className="d-flex">
                            <div className="col-6">
                                <TextInput
                                    name="first_name"
                                    label="First Name"
                                    value={userProfile.content.first_name}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="First name"
                                    className="w-90"
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    name="last_name"
                                    label="Last Name"
                                    value={userProfile.content.last_name}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Last name"
                                    className="w-90"
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <TextInput
                                name="company_name"
                                label="Company Name"
                                value={userProfile.content.company_name}
                                onChange={(e) => handleChange(e)}
                                placeholder="Company name"
                                className="w-95"
                            />
                        </div>
                    </div>

                    <div className="text-center mt-5">
                        <i role="button" className="bi bi-arrow-right-circle link-primary text-dark fs-1" onClick={() => handlePage()} />
                    </div>
                </div>
            }

            {currentPage === 3 &&
                <div className="h-100 w-75 m-auto overflow-auto">
                    <h2 className="fs-26 mb-1">Notification Settings</h2>
                    <hr className="m-0 mb-4" />

                    <h4 className="lead fs-5 mb-2">Opt-in to receive notifications via:</h4>
                    <div className="ms-3">
                        <div className="form-check form-switch mb-2">
                            <input type="checkbox" className="form-check-input align-middle"
                                   name="notify" value={userProfile.content.notifications.phone.notify}
                                   checked={userProfile.content.notifications.phone.notify}
                                   onChange={(e) => toggleReceiveNotifications("phone", 'checkbox', e)}/>
                            <span className="form-check-label">Phone (SMS)</span>
                        </div>

                        {userProfile.content.notifications.phone.notify &&
                            <div className="ms-2 mb-3">
                                <TextInput
                                    name="recipient"
                                    value={userProfile.content.notifications.phone.recipient}
                                    onChange={(e) => toggleReceiveNotifications("phone", 'string', e)}
                                    placeholder="Enter phone no"
                                    className="w-30"
                                />
                            </div>
                        }

                        <div className="form-check form-switch mb-2">
                            <input type="checkbox" className="form-check-input align-middle"
                                   name="notify" value={userProfile.content.notifications.email_address.notify}
                                   checked={userProfile.content.notifications.email_address.notify}
                                   onChange={(e) => toggleReceiveNotifications("email_address", 'checkbox', e)}/>
                            <span className="form-check-label">Email address</span>
                        </div>

                        {userProfile.content.notifications.email_address.notify &&
                            <div className="ms-2 mb-4">
                                <TextInput
                                    name="recipient"
                                    value={userProfile.content.notifications.email_address.recipient}
                                    onChange={(e) => toggleReceiveNotifications("email_address", 'string', e)}
                                    placeholder="Enter email address"
                                    className="w-30"
                                />
                            </div>
                        }
                    </div>

                    {(userProfile.content.notifications.phone.notify == true || userProfile.content.notifications.email_address.notify == true) &&
                        <div className="mt-5 ms-3">
                            <h4 className="lead fs-5 mb-2 text-xl">Notify me when a:</h4>
                            <div className="ms-2">
                                <div className="form-check form-switch mb-2">
                                    <input type="checkbox" className="form-check-input align-middle"
                                           name="notify_on_job" value={userProfile.content.notifications.notify_on_job}
                                           checked={userProfile.content.notifications.notify_on_job}
                                           onChange={(e) => toggleNotifications(e)}/>
                                    <span className="form-check-label">Job has been listed</span>
                                </div>

                                <div className="form-check form-switch mb-2">
                                    <input type="checkbox" className="form-check-input align-middle"
                                           name="notify_on_bid" value={userProfile.content.notifications.notify_on_bid}
                                           checked={userProfile.content.notifications.notify_on_bid}
                                           onChange={(e) => toggleNotifications(e)}/>
                                    <span className="form-check-label">Bid has been made on a job</span>
                                </div>

                                <div className="form-check form-switch mb-2">
                                    <input type="checkbox" className="form-check-input align-middle"
                                           name="notify_last_hour" value={userProfile.content.notifications.notify_last_hour}
                                           checked={userProfile.content.notifications.notify_last_hour}
                                           onChange={(e) => toggleNotifications(e)}/>
                                    <span className="form-check-label">Job has 1 hour remaining to bid</span>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="text-center mt-5">
                        <i role="button" className="bi bi-arrow-right-circle link-primary text-dark fs-1" onClick={() => handlePage()} />
                    </div>
                </div>
            }

            {currentPage === 4 &&
                <div className="h-100 w-75 m-auto overflow-auto">
                    <h2 className="p-0 m-0 fs-26 ">Vetting</h2>
                    <span className="text-secondary fs-14">Information will be used for verification purposes <span className="fst-italic fw-semibold">(for bin providers only)</span></span>
                    <hr className="m-0 mb-4 mt-2" />

                    <div>
                        <TextInput
                            name="address"
                            label="Business address"
                            value={userProfile.content.address}
                            onChange={(e) => handleChange(e)}
                            placeholder="Business address"
                            className="w-100"
                        />

                        <div className="mt-3">
                            <TextInput
                                name="phone_number"
                                label="Work / personal phone no"
                                value={userProfile.content.phone_number}
                                onChange={(e) => handleChange(e)}
                                placeholder="Phone no"
                                className="w-100"
                            />
                        </div>

                        <div className="d-flex mt-3">
                            <div className="col-6">
                                <TextInput
                                    name="abn"
                                    label="ABN"
                                    value={userProfile.content.abn}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="ABN"
                                    className="w-90"
                                />
                            </div>

                            <div className="col-6">
                                <TextInput
                                    name="acn"
                                    label="ACN"
                                    value={userProfile.content.acn}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="ACN"
                                    className="w-100"
                                />
                            </div>
                        </div>

                        <div className="mt-3">
                            <TextInput
                                name="website"
                                label="Website URL"
                                value={userProfile.content.website}
                                onChange={(e) => handleChange(e)}
                                placeholder="Website URL"
                                className="w-100"
                            />
                        </div>

                        <div className="mt-3">
                            <label className={`lead form-label fs-16 p-0 m-0 mb-1`}>Years in operation:</label>
                            <select className="form-control lead fs-16" placeholder="Years in operation">
                                <option className="lead fs-14">0-12 months</option>
                                <option>1-2 years</option>
                                <option>2-3 years</option>
                                <option>3+ years</option>
                            </select>
                        </div>
                    </div>

                    <div className="text-center mt-5">
                        <i role="button" className="bi bi-arrow-right-circle link-primary text-dark fs-1" onClick={() => handlePage()} />
                    </div>
                </div>
            }

            {currentPage === 5 &&
                <div role="button" className="onboarding h-100 w-100 overflow-auto" onClick={() => navigate("/listings")}>
                    <h2 className="text-center fs-32">All done!</h2>
                    <div role="button" className="bi bi-check-circle link-success text-dark fs-1 text-center mb-4" />
                </div>
            }

            <div className="page-display text-center mt-4">
                {pages.map((page) =>
                    renderButton(page)
                )}
            </div>
        </div>
    );
};

export default Onboarding;