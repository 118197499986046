// Account
import ProfileSettings from "../../components/pages/account/ProfileSettings";
import NotificationSettings from "../../components/pages/account/NotificationSettings";

// Admin
import Users from "../../components/pages/account/admin/Users";
import JobsAndBids from "../../components/pages/account/admin/JobsAndBids";

// Contractor
import Bids from "../../components/pages/account/contractor/Bids";
import Jobs from "../../components/pages/account/contractor/Jobs";

// Customer
import Orders from "../../components/pages/account/customers/Orders";


export const ACCOUNT_SIDE_NAVIGATION = [
    { constant: "profile_settings",
      label: "Profile Settings",
      icon: "bi bi-person-fill-gear",
      component: ProfileSettings},

    { constant: "notification_settings",
      label: "Notification Settings",
      icon: "bi bi-phone-vibrate-fill",
      component: NotificationSettings},

    // { constant: "payment_settings",
    //   label: "Payment Settings",
    //   icon: "bi bi-credit-card-fill",
    //   component: PaymentSettings},
];

export const ADMIN_SIDE_NAVIGATION = [
    { constant: "users",
      label: "Users",
      icon: "bi bi-people-fill",
      component: Users},

    { constant: "jobs_and_bids",
      label: "Jobs & Bids",
      icon: "bi bi-briefcase-fill",
      component: JobsAndBids},
];

export const CONTRACTOR_SIDE_NAVIGATION = [
    { constant: "my_jobs",
      label: "My Jobs",
      icon: "bi bi-briefcase-fill",
      component: Jobs},

    { constant: "my_bids",
      label: "My Bids",
      icon: "bi bi-bookmark-fill",
      component: Bids},
];

export const CUSTOMER_SIDE_NAVIGATION = [
    { constant: "my_orders",
      label: "My Orders",
      icon: "bi bi-list-task",
      component: Orders},

    /*{ constant: "my_requests",
      label: "My Requests",
      icon: "bi bi-send-fill",
      component: Requests}*/
];