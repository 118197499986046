// Admin role
export const ADMIN_ROLE_ID = 1;
export const ADMIN_ROLE_CONSTANT = "admin";
export const ADMIN_ROLE_LABEL = "admin";

// Contractor role
export const CONTRACTOR_ROLE_ID = 2;
export const CONTRACTOR_ROLE_CONSTANT = "contractor";
export const CONTRACTOR_ROLE_LABEL = "Contractor";

// Customer role
export const CUSTOMER_ROLE_ID = 3;
export const CUSTOMER_ROLE_CONSTANT = "customer";
export const CUSTOMER_ROLE_LABEL = "Customer";