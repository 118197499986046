export const JOB_DETAILS_STRUCTURE = [
    { constant: "bin_details",
      label: "Bin details",
      properties: [
          { constant: "name", label: "Bin size", icon: "bi bi-arrows-angle-expand"},
          { constant: "waste_type", label: "Bin type", icon: "bi bi-trash2-fill"},
      ]
    },

    { constant: "additional_items",
      label: "Additional items",
      properties: [
          { constant: "car_tyres", label: "Car tyres", icon: "bi bi-"},
          { constant: "carpet", label: "Carpet", icon: "bi bi-"},
          { constant: "king_queen_mattress", label: "King queen mattress", icon: "bi bi-"},
          { constant: "single_double_mattress", label: "Single double mattress", icon: "bi bi-"},
          { constant: "stumps", label: "Stumps", icon: "bi bi-"},
          { constant: "truck_tyres", label: "Truck tyres", icon: "bi bi-"}
      ]
    },

    { constant: "location_details",
      label: "Location details",
      properties: [
          { constant: "address_1", label: "Address line", icon: "bi bi-geo-alt-fill"},
          { constant: "suburb", label: "Suburb", icon: "bi bi-buildings"},
          { constant: "state", label: "State", icon: "bi bi-map"},
          { constant: "postcode", label: "Postcode", icon: "bi bi-mailbox"},
          { constant: "country", label: "Country", icon: "bi bi-globe2"},
      ]
    },

    { constant: "delivery_details",
      label: "Delivery details",
      properties: [
          { constant: "delivery_date", label: "Date delivery", icon: "bi bi-calendar"},
          { constant: "pick_up_date", label: "Pickup delivery", icon: "bi bi-calendar-check"},
          { constant: "preferred_delivery_time", label: "Preferred time", icon: "bi bi-clock"},
      ]
    },
];