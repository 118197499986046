import {createSlice} from "@reduxjs/toolkit";

const toasts = createSlice({
    name: "toasts",
    initialState: [],
    reducers: {
        setToast: (state, action) => {
            return action.payload
        },
    }
})

export const {setToast} = toasts.actions;
export default toasts.reducer;