import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

import "./../../../assets/styles/authenticate.css";
import skipbinco_logo from "./../../../assets/images/SkipBinCo-Logo.webp";

import TextInput from "../../form/TextInput";

import {setUserProfile} from "../../../utils/slices/userProfile.slice";
import {postUnauthenticated} from "../../../utils/actions/post.actions";
import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {setBanner} from "../../../utils/slices/banner.slice";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const banner = useSelector(state => state.banner);
    const userProfile = useSelector(state => state.userProfile);
    const navigation = useSelector(state => state.navigation);

    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [errors, setErrors] = useState({});
    const [loginDetails, setLoginDetails] = useState({
        email_address: "",
        password: "",
    });

    useEffect(() => {
        dispatch(setBanner({
            ...banner,
            label: 'Login'
        }));
    }, [navigation]);

    useEffect(() => {
        if(userProfile && userProfile.id) {
            navigate('/listings');
        }
    }, [userProfile]);

    useEffect(() => {
        let email_address = document.getElementById("email_address");
        if(email_address && !isAuthenticating) {
            email_address.focus();
        }
    }, [isAuthenticating]);

    function validateForm() {
        setIsAuthenticating(true);
        let _errors = {}

        if(isEmpty(loginDetails.email_address)) {
            _errors['email_address'] = "Email address field cannot be empty";
        }
        if(isEmpty(loginDetails.password)) {
            _errors['password'] = "Password field cannot be empty";
        }

        setErrors(_errors);
        if(isObjectEmpty(_errors)) {
            authenticateUser();
        }else{
            setIsAuthenticating(false);
        }
    }

    function authenticateUser() {
        let url = API_ENDPOINT + "v1/Users/authenticateUser"
        let params = {
            email_address: loginDetails.email_address,
            password: loginDetails.password
        }

        postUnauthenticated(url, params).then(results => {
            let success = results.success;

            if(success) {
                dispatch(setUserProfile(results.data));
                navigate('/listings');
            }else{
                let _errors = {}
                _errors['authenticate'] = results.message;

                setErrors(_errors);
                setIsAuthenticating(false);
            }
        });
    }

    function handleLoginChange(event){
        setLoginDetails({...loginDetails, [event.target.name]: event.target.value});
    }

    function handleKeyDown(event) {
        if (event.keyCode == 13) {
            let login_btn = document.getElementById('login_btn');
            if(login_btn) {
                login_btn.click();
            }
        }
    }

    return (
        <div className="border border-1 rounded col-11 col-lg-5 m-auto mt-5 p-4 shadow form-authenticate">
            <div className="mb-4">
                <img className="m-auto" src={skipbinco_logo} width={150} height={100} alt="logo" />
            </div>
            <div>
                <h2 className="text-center lead fs-3 fw-normal m-0 p-0">Welcome</h2>
                <div className="text-center py-2 text-secondary w-100">Do not have an account? <Link to={'/signup'} className="link-primary lead text-decoration-none fs-6">Create today!</Link></div>
            </div>

            {errors.authenticate &&
                <div className="text-center mt-2">
                    <label className="text-danger">Email and/or password was incorrect. Please try again</label>
                </div>
            }

            <div className="mt-4 m-auto">
                <TextInput
                    name="email_address"
                    value={loginDetails.email_address}
                    placeholder="Email address"
                    onChange={(e) => handleLoginChange(e)}
                    error={errors.email_address}
                    className="form-control m-auto"
                    labelClassName="text-dark pb-1"
                    errorClassName="m-auto text-danger mt-1 fs-14 ms-1"
                    disabled={isAuthenticating}
                />
            </div>

            <div className="mt-4">
                <TextInput
                    type="password"
                    name="password"
                    value={loginDetails.password}
                    placeholder="Password"
                    onChange={(e) => handleLoginChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    error={errors.password}
                    className="form-control m-auto"
                    labelClassName="text-dark pb-1"
                    errorClassName="m-auto text-danger mt-1 fs-14 ms-1"
                    disabled={isAuthenticating}
                />
            </div>

            <div className="m-auto mt-4 text-start">
                <button id="login_btn" className="btn btn-warning d-flex w-100 p-1 mt-1 py-2 align-items-center" onClick={() => validateForm()} disabled={isAuthenticating}>
                    <div className="fa fa-user-o col-6 text-start align-items-center ps-2" />
                    <div className="align-items-center">
                        {isAuthenticating ?
                            <div className="spinner-border spinner-grow-sm" role="status" />
                            : 'Login'
                        }
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Login;
