import React from 'react';

const TextAreaInput = (props) => {
    return (
        <textarea
            id={props.id || props.name}
            name={props.name}
            value={props.value}
            rows={props.rows}
            cols={props.cols}
            disabled={props.disabled}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            className={`form-control ${props.className}`}
            style={props.style}
        />
    );
};

export default TextAreaInput;
