import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import reportWebVitals from './reportWebVitals';
import {store} from "./store";

import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'font-awesome/css/font-awesome.min.css';
import 'tailwindcss/tailwind.css';
import './assets/styles/custom.css';
import './assets/styles/theme.css';
import './assets/styles/colours.css';

import App from "./App";
import AppRoutes from "./AppRoutes";
import Exception from "./components/common/Exception";

const root = ReactDOM.createRoot(document.getElementById("root"));
window.addEventListener("exception", (event) => {
    root.render(
        <Provider store={store}>
            <Exception error={event.detail.error} />
        </Provider>
    )
});

root.render(
    <Provider store={store}>
        <AppRoutes />
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
