import React, {useEffect, useState} from 'react';

import "../../assets/styles/checkout.css";
import TextInput from "../form/TextInput";

const CheckoutForm = (props) => {
    const stripe = window.Stripe("pk_test_hEH3BTfaGWkiCMKzO67jj8y200C78W2aka");
    const [fullName, setFullName] = useState("");
    let elements = null;
    let cardElementContainer = null;
    let cardElement = null;

    useEffect(() => {
        elements = stripe.elements();

        cardElement = elements.create("card");
        cardElement.mount(cardElementContainer);
    }, []);

    async function handleFormSubmit(ev) {
        ev.preventDefault();
        props.selectWinningBid(props.bid, props.job, 'test');

        // stripe.createPaymentMethod({
        //     type: "card",
        //     card: cardElement
        // }).then(results => {
        //     let payment_id = results.paymentMethod.id;
        //     props.selectWinningBid(props.bid, props.job, payment_id);
        // });
    }

    function setCardElementContainerRef(element) {
        cardElementContainer = element;
    }

    return (
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-70 h-45 m-auto">
                    <div className="modal-header">
                        <h3 className="lead fw-semibold fs-2 pb-0 mb-0">Make your payment</h3>
                    </div>

                    <div className={`modal-body`} style={{overflowY: "scroll"}}>
                        <div className="mb-4">
                            <TextInput
                                label="Name on card"
                                value={fullName}
                                placeholder="Full name"
                                onChange={(e) => setFullName(e.target.value)}
                                className="rounded-1 py-2 border border-secondary-subtle fs-14 text-secondary-emphasis"
                            />
                        </div>
                        <form className="checkout-form">
                            <div ref={setCardElementContainerRef} className="card-element-container" />
                        </form>
                    </div>

                    <div className="modal-footer flex-nowrap mx-3">
                        <div className="col-6">
                            <button className="w-100 btn btn-danger p-1" onClick={() => props.onClose()}>Cancel</button>
                        </div>
                        <div className="col-6">
                            <button className="w-100 btn-theme darkblue p-1" onClick={(e) => handleFormSubmit(e)}>Confirm Payment</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutForm;