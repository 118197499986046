import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeModal} from "../../utils/slices/modal.slice";

import "./../../assets/styles/modal.css";

const Modal = () => {
    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal);

    useEffect(() => {
        document.body.style.overflowY = modal.config && modal.config.component
            ? 'hidden'
            : 'scroll';
    }, [modal]);

    function onClose() {
        modal.onClose && modal.onClose();
        dispatch(closeModal({}));
    }

    return modal.config && modal.config.component ?
        <modal.config.component {...modal.config.props} title={modal.config.title} onClose={onClose} />
    :null
};

export default Modal;