import {createSlice} from "@reduxjs/toolkit";

const banner = createSlice({
    name: "banner",
    initialState: {label: "", hide: false},
    reducers: {
        setBanner: (state, {payload}) => {
            return {
                ...state,
                ...payload
            };
        }
    }
})

export const {setBanner} = banner.actions;
export default banner.reducer;