import {createSlice} from "@reduxjs/toolkit";

const userProfile = createSlice({
    name: "userProfile",
    initialState: localStorage.getItem('user') == 'undefined' ? {} : JSON.parse(localStorage.getItem('user')),
    reducers: {
        setUserProfile: (state, {payload}) => {
            localStorage.setItem('user', JSON.stringify(payload));
            return {
                ...state,
                ...payload,
            };
        },
        logoutUserProfile: (state, {payload}) => {
            localStorage.removeItem('user');
            window.location.href = "/login";
            return {}
        },
    }
})

export const {setUserProfile, logoutUserProfile} = userProfile.actions;
export default userProfile.reducer;
