import {configureStore} from "@reduxjs/toolkit";

import modalSlice from "./utils/slices/modal.slice";
import toastSlice from "./utils/slices/toasts.slice";
import bannerSlice from "./utils/slices/banner.slice";
import navigationSlice from "./utils/slices/navigation.slice";
import userProfileSlice from "./utils/slices/userProfile.slice";

export const store = configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
    reducer: {
        modal: modalSlice,
        toasts: toastSlice,
        banner: bannerSlice,
        navigation: navigationSlice,
        userProfile: userProfileSlice
    },
})
