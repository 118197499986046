import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import TextInput from "../../form/TextInput";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setUserProfile} from "../../../utils/slices/userProfile.slice";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {CONTRACTOR_ROLE_ID} from "../../../utils/constants/roles.constants";
import {isEmpty} from "../../../utils/helpers/app.helpers";
import {setToast} from "../../../utils/slices/toasts.slice";

const ProfileSettings = () => {
    const dispatch = useDispatch();
    const toasts = useSelector(state => state.toasts);
    const userProfile = useSelector(state => state.userProfile);

    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState({
        password: "",
        confirm_password: ""
    });

    function updateUser(profile) {
        let url = API_ENDPOINT + "v1/Users/updateUser";
        let params = {
            user: profile
        }

        postAuthenticated(url, params).then(results => {

        }).catch(error => alert(error))
    }

    function changePassword() {
        let url = API_ENDPOINT + "v1/Users/changePassword";
        let params = {
            user_id: userProfile.id,
            password: password.confirm_password
        }

        postAuthenticated(url, params).then(results => {
            let toast = {
                ...toasts,
                [toasts.length]: {
                    head: "Notification",
                    body: <span className="text-success">Password was successfully changed</span>,
                }
            };
            dispatch(setToast(Object.assign([], toast)));

            setPassword({
                ...password,
                password: "",
                confirm_password: ""
            });
        });
    }

    function validatePassword() {
        let _errors = {};

        if(isEmpty(password.password)) _errors['password'] = "Field cannot be empty";
        if(isEmpty(password.confirm_password)) _errors['confirm_password'] = "Field cannot be empty";
        if((!isEmpty(password.password) && !isEmpty(password.confirm_password)) && password.password != password.confirm_password){
            _errors['password'] = "Passwords do not match";
            _errors['confirm_password'] = "Passwords do not match";
        }

        setErrors(_errors);
        if(Object.keys(_errors).length === 0) {
            changePassword();
        }
    }

    function handleChange(event) {
        let profile = {
            ...userProfile,
            content: {
                ...userProfile.content,
                [event.target.name]: event.target.value
            }
        }

        dispatch(setUserProfile(profile));
        updateUser(profile);
    }

    function handlePassword(event) {
        setPassword({...password, [event.target.name]: event.target.value});
    }

    return (
        <div className="mt-3">
            <div className="mt-2 mx-auto">
                <div className="d-flex">
                    <div className="col-6">
                        <TextInput
                            name="first_name"
                            label="First Name"
                            value={userProfile.content.first_name}
                            onChange={(e) => handleChange(e)}
                            placeholder="First name"
                            className="w-90"
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            name="last_name"
                            label="Last Name"
                            value={userProfile.content.last_name}
                            onChange={(e) => handleChange(e)}
                            placeholder="Last name"
                            className="w-90"
                        />
                    </div>
                </div>

                <div className="mt-2">
                    <TextInput
                        name="company_name"
                        label="Company Name"
                        value={userProfile.content.company_name}
                        onChange={(e) => handleChange(e)}
                        placeholder="Company name"
                        className="w-95"
                    />
                </div>

                {userProfile.role_id == CONTRACTOR_ROLE_ID &&
                    <div className="mt-5">
                        <h3 className="fs-20">Vetting Details</h3>
                        <hr className="p-0 m-0 hr w-95 mt-1" />

                        <TextInput
                            name="address"
                            label="Business address"
                            value={userProfile.content.address}
                            onChange={(e) => handleChange(e)}
                            placeholder="Business address"
                            className="w-95"
                        />

                        <div className="mt-3">
                            <TextInput
                                name="phone_number"
                                label="Work / personal phone no"
                                value={userProfile.content.phone_number}
                                onChange={(e) => handleChange(e)}
                                placeholder="Phone no"
                                className="w-95"
                            />
                        </div>

                        <div className="d-flex mt-3">
                            <div className="col-6">
                                <TextInput
                                    name="abn"
                                    label="ABN"
                                    value={userProfile.content.abn}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="ABN"
                                    className="w-95"
                                />
                            </div>

                            <div className="col-6">
                                <TextInput
                                    name="acn"
                                    label="ACN"
                                    value={userProfile.content.acn}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="ACN"
                                    className="w-90"
                                />
                            </div>
                        </div>

                        <div className="mt-3">
                            <TextInput
                                name="website"
                                label="Website URL"
                                value={userProfile.content.website}
                                onChange={(e) => handleChange(e)}
                                placeholder="Website URL"
                                className="w-95"
                            />
                        </div>

                        <div className="mt-3">
                            <label className={`lead form-label fs-16 p-0 m-0 mb-1`}>Years in operation:</label>
                            <select className="form-control lead fs-16 w-95" placeholder="Years in operation">
                                <option className="lead fs-14">0-12 months</option>
                                <option>1-2 years</option>
                                <option>2-3 years</option>
                                <option>3+ years</option>
                            </select>
                        </div>
                    </div>
                }
            </div>

            <h3 className="mt-5 fs-20">Change Password</h3>
            <hr className="p-0 m-0 hr w-95 mt-1" />

            <div className="mt-2">
                <div className="d-flex">
                    <div className="col-6">
                        <TextInput
                            type="password"
                            name="password"
                            label="Password"
                            value={password.password}
                            onChange={(e) => handlePassword(e)}
                            placeholder="Password"
                            className="w-90"
                            error={errors.password}
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            type="password"
                            name="confirm_password"
                            label="Confirm Password"
                            value={password.confirm_password}
                            onChange={(e) => handlePassword(e)}
                            placeholder="Confirm password"
                            className="w-90"
                            error={errors.confirm_password}
                        />
                    </div>
                </div>

                <button className={`btn-theme darkblue text-white rounded ${errors.password ? 'mt-2' : 'mt-3'}`} onClick={() => validatePassword()}>
                    Save Password
                </button>
            </div>
        </div>
    );
};

export default ProfileSettings;