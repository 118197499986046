import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import "./../../../assets/styles/account.css";

import {setNavigation} from "../../../utils/slices/navigation.slice";
import {ADMIN_ROLE_ID, CONTRACTOR_ROLE_ID, CUSTOMER_ROLE_ID} from "../../../utils/constants/roles.constants";
import {AUTHENTICATED_HEADER_NAVIGATION} from "../../../utils/structures/navigation.structures";
import {
    ACCOUNT_SIDE_NAVIGATION,
    ADMIN_SIDE_NAVIGATION,
    CONTRACTOR_SIDE_NAVIGATION,
    CUSTOMER_SIDE_NAVIGATION
} from "../../../utils/structures/account.structures";
import {useNavigate} from "react-router-dom";

const Account = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navigation = useSelector(state => state.navigation);
    const userProfile = useSelector(state => state.userProfile);
    const [currentMenu, setCurrentMenu] = useState({});

    useEffect(() => {
        dispatch(setNavigation({
            ...navigation,
            hide: false,
            header: {
                ...AUTHENTICATED_HEADER_NAVIGATION[1]
            },
        }));

        navigation.account && navigation.account.constant
            ? setCurrentMenu(navigation.account)
            : setCurrentMenu(ACCOUNT_SIDE_NAVIGATION[0]);
    }, []);

    function handleAccountNav(nav) {
        setCurrentMenu(nav)
        dispatch(setNavigation({
            ...navigation,
            hide: false,
            header: {...navigation.header},
            account: nav
        }));
    }

    return (
        currentMenu && currentMenu.constant &&
        <div className="d-flex w-90 m-auto">
            <div className="w-20 me-4">
                <ul className="list-group py-4">
                    <li role="button" className="dashboard-nav p-1 btn-theme darkblue text-white" onClick={() => navigate('/onboarding')}>
                        <i className={`fs-5 me-2 align-middle`} />
                        <span className="align-middle">Visit Onboarding</span>
                    </li>

                    <hr className="p-0 m-0 mb-3" />

                    {ACCOUNT_SIDE_NAVIGATION.map((nav, index) =>
                        <li key={index} role="button" className={`dashboard-nav ${currentMenu.constant == nav.constant ? 'active' : ''}`} onClick={() => handleAccountNav(nav)}>
                            <i className={`${nav.icon} fs-5 me-2 align-middle`} />
                            <span className="align-middle">{nav.label}</span>
                        </li>
                    )}
                </ul>

                {userProfile.role_id == CUSTOMER_ROLE_ID &&
                    <React.Fragment>
                        <h3 className="lead fw-normal ms-1">Customer</h3>
                        <hr className="p-0 m-0 mb-3" />

                        <ul className="list-group pb-4">
                            {CUSTOMER_SIDE_NAVIGATION.map((nav, index) =>
                                <li key={index} role="button" className={`dashboard-nav ${currentMenu.constant == nav.constant ? 'active' : ''}`} onClick={() => handleAccountNav(nav)}>
                                    <i className={`${nav.icon} fs-5 me-2 align-middle`} />
                                    <span className="align-middle">{nav.label}</span>
                                </li>
                            )}
                        </ul>
                    </React.Fragment>
                }

                {userProfile.role_id == CONTRACTOR_ROLE_ID &&
                    <React.Fragment>
                        <h3 className="lead fw-normal ms-1">Contractor</h3>
                        <hr className="p-0 m-0 mb-3 mt-1" />

                        <ul className="list-group pb-4">
                            {CONTRACTOR_SIDE_NAVIGATION.map((nav, index) =>
                                <li key={index} role="button" className={`dashboard-nav ${currentMenu.constant == nav.constant ? 'active' : ''}`} onClick={() => handleAccountNav(nav)}>
                                    <i className={`${nav.icon} fs-5 me-2 align-middle`} />
                                    <span className="align-middle">{nav.label}</span>
                                </li>
                            )}
                        </ul>
                    </React.Fragment>
                }

                {userProfile.role_id == ADMIN_ROLE_ID &&
                    <React.Fragment>
                        <h3 className="lead fw-normal ms-1">Admin</h3>
                        <hr className="p-0 m-0 mb-3 mt-1" />

                        <ul className="list-group pb-4">
                            {ADMIN_SIDE_NAVIGATION.map((nav, index) =>
                                <li key={index} role="button" className={`dashboard-nav ${currentMenu.constant == nav.constant ? 'active' : ''}`} onClick={() => handleAccountNav(nav)}>
                                    <i className={`${nav.icon} fs-5 me-2 align-middle`} />
                                    <span className="align-middle">{nav.label}</span>
                                </li>
                            )}
                        </ul>
                    </React.Fragment>
                }
            </div>

            <div className="col-9 mx-auto mt-3 ms-3">
                <h3 className="text-xl font-semibold">{currentMenu.label}</h3>
                <hr className="mt-2" />
                <currentMenu.component />
            </div>
        </div>
    );
};

export default Account;