import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

import TextInput from "../../form/TextInput";
import skipbinco_logo from "../../../assets/images/SkipBinCo-Logo.webp";

import {setBanner} from "../../../utils/slices/banner.slice";
import {setUserProfile} from "../../../utils/slices/userProfile.slice";
import {postUnauthenticated} from "../../../utils/actions/post.actions";
import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const Signup = (props) => {
    const USER_TYPE = props.userType;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const banner = useSelector(state => state.banner);
    const userProfile = useSelector(state => state.userProfile);
    const navigation = useSelector(state => state.navigation);

    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [errors, setErrors] = useState({});
    const [signupDetails, setSignupDetails] = useState({
        username: "",
        email_address: "",
        password: "",
        confirm_password: ""
    });

    useEffect(() => {
        dispatch(setBanner({
            ...banner,
            label: 'Signup'
        }));
    }, [navigation]);

    useEffect(() => {
        if(userProfile) {
            navigate('/listings');
        }
    }, [userProfile]);

    useEffect(() => {
        let username = document.getElementById("username");
        if(username && !isAuthenticating) {
            username.focus();
        }
    }, [isAuthenticating]);

    function handleSignupChange(event) {
        setSignupDetails({...signupDetails, [event.target.name]: event.target.value});
    }

    function validateForm() {
        let _errors = {}

        if(isEmpty(signupDetails.email_address)) {
            _errors['email_address'] = "Email address field cannot be empty";
        }
        if(isEmpty(signupDetails.password)) {
            _errors['password'] = "Password field cannot be empty";
        }
        if(isEmpty(signupDetails.confirm_password)) {
            _errors['confirm_password'] = "Confirm password field cannot be empty";
        }
        if(!isEmpty(signupDetails.confirm_password) && !isEmpty(signupDetails.password) && signupDetails.password !== signupDetails.confirm_password) {
            _errors['password'] = "Passwords do not match";
            _errors['confirm_password'] = "Passwords do not match";
        }

        setErrors(_errors);
        if(isObjectEmpty(_errors)) {
            createUser();
        }else{
            setIsAuthenticating(false);
        }
    }

    function createUser() {
        let url = API_ENDPOINT + "v1/Users/createUser"
        let params = {
            email_address: signupDetails.email_address,
            password: signupDetails.password,
            user_type: USER_TYPE
        }

        postUnauthenticated(url, params).then(results => {
            let isSuccessful = results.success;

            if(isSuccessful) {
                dispatch(setUserProfile(results.data));
                return navigate("/onboarding");
            }else{
                let _errors = {};
                _errors['authenticate'] = results.message

                setErrors(_errors);
                setIsAuthenticating(false);
            }
        }).catch(error => alert(error))
    }

    return (
        <div className="border border-1 rounded col-11 col-lg-5 m-auto mt-5 p-4 shadow form-authenticate">
            <div className="mb-4">
                <img className="m-auto" src={skipbinco_logo} width={150} height={100} alt="logo" />
            </div>
            <div>
                <h2 className="text-center lead fs-3 fw-normal m-0 p-0">Welcome</h2>
                <div className="text-center py-2 text-secondary">Already have an account? <Link to={'/login'} className="link-primary lead text-decoration-none fs-6">Sign in!</Link></div>
            </div>

            {errors.authenticate &&
                <div className="text-center mt-2">
                    <label className="text-danger">{errors.authenticate}</label>
                </div>
            }

            <div className="mt-3 m-auto">
                <TextInput
                    name="email_address"
                    value={signupDetails.email_address}
                    placeholder="Email Address"
                    error={errors.email_address}
                    onChange={(e) => handleSignupChange(e)}
                    className="form-control m-auto"
                    labelClassName="text-dark pb-1"
                    errorClassName="m-auto text-danger mt-1 fs-14 ms-1"
                    disabled={isAuthenticating}
                />
            </div>

            <div className="mt-3">
                <TextInput
                    type="password"
                    name="password"
                    value={signupDetails.password}
                    placeholder="Password"
                    error={errors.password}
                    onChange={(e) => handleSignupChange(e)}
                    className="form-control m-auto"
                    labelClassName="text-dark pb-1"
                    errorClassName="m-auto text-danger mt-1 fs-14 ms-1"
                    disabled={isAuthenticating}
                />
            </div>

            <div className="mt-3">
                <TextInput
                    type="password"
                    name="confirm_password"
                    value={signupDetails.confirm_password}
                    placeholder="Confirm Password"
                    error={errors.confirm_password}
                    onChange={(e) => handleSignupChange(e)}
                    className="form-control m-auto"
                    labelClassName="text-dark pb-1"
                    errorClassName="m-auto text-danger mt-1 fs-14 ms-1"
                    disabled={isAuthenticating}
                />
            </div>

            <div className="m-auto mt-4 text-start">
                <button className="btn btn-warning d-flex w-100 p-1 mt-1 py-2 align-items-center" onClick={() => validateForm()} disabled={isAuthenticating}>
                    <div className="fa fa-user-o col-6 text-start align-items-center ps-2" />
                    {isAuthenticating ?
                        <div className="spinner-border spinner-grow-sm" role="status" />
                        : 'Signup'
                    }
                </button>
            </div>
        </div>
    );
};

export default Signup;
