import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import "../../assets/styles/review.css";

import {API_ENDPOINT} from "../../utils/constants/app.constants";
import {setToast} from "../../utils/slices/toasts.slice";
import {postAuthenticated} from "../../utils/actions/post.actions";
import TextAreaInput from "../form/TextAreaInput";

const ReviewForm = (props) => {
    const dispatch = useDispatch();
    const toasts = useSelector(state => state.toasts);

    const [errors, setErrors] = useState({});
    const [review, setReview] = useState({
        comment: "",
        rating: 0
    });

    function submitReview() {
        let url = API_ENDPOINT + "v1/Reviews/submitReview";
        let params = {
            job_id: props.job.id,
            comment: review.comment,
            rating: review.rating
        };

        postAuthenticated(url, params).then(results => {
            let toast = {
                ...toasts,
                [toasts.length]: {
                    head: "Notification",
                    body: <span className="text-success">Review was successfully submitted</span>,
                }
            };
            dispatch(setToast(Object.assign([], toast)));
            props.onClose();
        })
    }

    function validateForm() {
        let _errors = {};

        if(review.comment.length === 0){ _errors['comment'] = "Please leave a comment";}
        if(review.rating == 0){ _errors['rating'] = "Please select a rating";}

        setErrors(_errors);
        if(Object.keys(_errors).length === 0) {
            submitReview();
        }
    }

    function handleChange(event) {
        setReview({...review, [event.target.name]: event.target.value});
    }

    return (
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-70 h-55 m-auto">
                    <div className="modal-header">
                        <h3 className="lead fw-semibold fs-2 pb-0 mb-0">Leave a review</h3>
                    </div>

                    <div className={`modal-body`} style={{overflowY: "scroll"}}>
                        <label className="text-center lead w-100 m-auto mt-1">Give a rating out of 5:</label>
                        <div className="d-flex flex-row-reverse rate text-center w-20 m-auto">
                            {[5,4,3,2,1].map(index =>
                                <React.Fragment>
                                    <input type="radio" name="rating" id={`star${index}`} value={index} onClick={(e) => handleChange(e)} />
                                    <label className="m-auto" htmlFor={`star${index}`} />
                                </React.Fragment>
                            )}
                        </div>
                        {errors.rating && <div className={`lead m-auto text-center w-100 text-danger fs-6 mt-1 mb-2`}>{errors.rating}</div>}

                        <div className="w-60 m-auto">
                            <div className="lead">Comments:</div>
                            <TextAreaInput
                                id='comment'
                                name="comment"
                                rows="6"
                                cols="60"
                                onChange={(e) => handleChange(e)}
                                error={errors.comment}
                                className={`form-control text-secondary-emphasis fs-14 ${errors.comment ? 'border border-danger border-1' : ''}`}
                            />
                        </div>
                    </div>

                    <div className="modal-footer flex-nowrap mx-3">
                        <div className="col-6 text-center">
                            <button className="w-50 btn btn-danger p-1" onClick={() => props.onClose()}>Cancel</button>
                        </div>
                        <div className="col-6 text-center">
                            <button className="w-50 btn-theme darkblue p-1" onClick={(e) => validateForm()}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewForm;