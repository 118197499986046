import React from 'react';

const Confirmation = (props) => {
    return (
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>{props.title}</h5>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={() => {props.onDecline(); props.onClose();}}>{props.declineMessage}</button>
                        <button type="button" className="btn btn-primary" onClick={() => {props.onConfirm(); props.onClose();}}>{props.confirmMessage}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Confirmation;