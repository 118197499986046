import React from 'react';

const Loader = (props) => {
    return (
        <div className={`${props.className} text-center`}>
            <div className="spinner-border" role="status" style={{width: "20px", height: "20px"}}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export default Loader;
