import React, {useEffect, useState} from 'react';
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";
import {useSelector} from "react-redux";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import {Link} from "react-router-dom";
import Loader from "../../../common/Loader";

const Bids = () => {
    const userProfile = useSelector(state => state.userProfile);
    const [init, setInit] = useState(false);
    const [bids, setBids] = useState([]);

    useEffect(() => {
        getBidsByUserId();
        setInit(true);
    }, []);

    function getBidsByUserId() {
        let url = API_ENDPOINT + "v1/Bids/getBidsByUserId";
        let params = {
            user_id: userProfile.id
        }

        postAuthenticated(url, params).then(results => {
            setBids(results.data);
        })
    }

    function hasWinningBid(bid) {
        if(bid.job.winning_bid_id == null) {
            return 'open';
        }else{
            return bid.id == bid.job.winning_bid_id;
        }
    }

    return (
        init ?
        <div>
            {bids.length === 0 ?
                <div className="lead fs-16 text-secondary mt-2">No bids were found</div>
            :
                <React.Fragment>
                    <div className="fs-14 text-secondary mb-1 mt-2">Results: {bids.length}</div>
                    {bids.map((bid, index) =>
                        <div key={index} className="border border-1 mb-3 rounded">
                            <div className="p-3 ms-2 pt-1 pb-1">
                            <span className={`p-0 m-0 fs-14 ${hasWinningBid(bid) === 'open' ? 'text-primary' : hasWinningBid(bid) ? 'text-success' : 'text-danger'}`}>
                                {hasWinningBid(bid) === 'open' ? 'Open' : hasWinningBid(bid) ? 'Won' : 'Closed'}
                            </span>
                                <div className="d-flex">
                                    <div className="col-6">
                                        <div>Job: <Link to={`/job/${bid.job.hash}`} className="text-primary-emphasis">{bid.request.content.name} - {bid.request.content.waste_type}</Link></div>
                                        <div className="text-secondary fs-14">Bid Amount: ${bid.content.amount}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            }
        </div>
        : <Loader />
    );
};

export default Bids;