import {createSlice} from "@reduxjs/toolkit";

const navigation = createSlice({
    name: "navigation",
    initialState: {
        hide: false,
        header: {},
        account: {}
    },
    reducers: {
        setNavigation: (state, {payload}) => {
            return {
                ...state,
                ...payload
            };
        }
    }
})

export const {setNavigation} = navigation.actions;
export default navigation.reducer;