import React, {useEffect} from 'react';
import {useSelector} from "react-redux";

const Banner = () => {
    const banner = useSelector(state => state.banner);
    return (
        banner && !banner.hide ?
            <div className="mt-3 bg-warning w-100 text-center fw-light fs-3 mb-3">
                <div className={`${banner.label != "" ? 'p-3' : ''}`}>{banner.label}</div>
            </div>
        :null
    );
};

export default Banner;
