import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import JobsAndBids from "../admin/JobsAndBids";
import Jobs from "../contractor/Jobs";
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import Loader from "../../../common/Loader";

const Orders = () => {
    const userProfile = useSelector(state => state.userProfile);
    const [init, setInit] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [orders, setOrders] = useState([]);
    const [menu, setMenu] = useState("orders");

    useEffect(() => {
        getCustomerJobs();
        getCustomerOrders();
        setInit(true);
    }, []);

    function getCustomerJobs() {
        let url = API_ENDPOINT + "v1/Jobs/getCustomerJobs";
        let params = {
            user_id: userProfile.id
        };

        postAuthenticated(url, params).then(results => {
            setJobs(results.data);
        })
    }

    function getCustomerOrders() {
        let url = API_ENDPOINT + "v1/Jobs/getCustomerOrders";
        let params = {
            user_id: userProfile.id
        };

        postAuthenticated(url, params).then(results => {
            setOrders(results.data);
        });
    }

    return (
        init ?
            <div>
                <div className="d-flex mb-2 mt-3">
                    <div role="button" className={`col-6 border border-1 text-center py-1 ${menu === 'orders' ? 'bg-primary' : ''}`} onClick={() => setMenu("orders")}>
                        <span className={`${menu === 'orders' ? 'text-white' : 'text-secondary'} lead fs-16`}>Orders</span>
                    </div>

                    <div role="button" className={`col-6 border border-1 text-center py-1 ${menu === 'jobs' ? 'bg-primary' : ''}`} onClick={() => setMenu("jobs")}>
                        <span className={`${menu === 'jobs' ? 'text-white' : 'text-secondary'} lead fs-16`}>Jobs</span>
                    </div>
                </div>

                {menu === "orders" && <JobsAndBids orders={orders} getCustomerJobs={getCustomerOrders} /> }
                {menu === "jobs" && <Jobs jobs={jobs} getCustomerJobs={getCustomerJobs} /> }
            </div>
        : <Loader />
    );
};

export default Orders;