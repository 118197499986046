import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {setUserProfile} from "../../../utils/slices/userProfile.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import TextInput from "../../form/TextInput";

const NotificationSettings = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile);

    function updateUser(profile) {
        let url = API_ENDPOINT + "v1/Users/updateUser";
        let params = {
            user: profile
        }

        postAuthenticated(url, params).then(results => {

        }).catch(error => alert(error))
    }

    function toggleNotifications(event) {
        let name = event.target.name;
        let value = !userProfile.content.notifications[name];

        let profile = {
            ...userProfile,
            content: {
                ...userProfile.content,
                notifications: {
                    ...userProfile.content.notifications,
                    [name]: value
                }
            }
        }

        dispatch(setUserProfile(profile));
        updateUser(profile);
    }

    function toggleReceiveNotifications(field, type, event) {
        let name = event.target.name;
        let value = type == 'string' ? event.target.value : !userProfile.content.notifications[field][name];

        let profile = {
            ...userProfile,
            content: {
                ...userProfile.content,
                notifications: {
                    ...userProfile.content.notifications,
                    [field]: {
                        ...userProfile.content.notifications[field],
                        [name]: value
                    }
                }
            }
        }

        dispatch(setUserProfile(profile));
        updateUser(profile);
    }

    return (
        <div className="mt-3">
            <h4 className="lead fs-5 mb-2">Opt-in to receive notifications via:</h4>
            <div className="ms-3">
                <div className="form-check form-switch mb-2">
                    <input type="checkbox" className="form-check-input align-middle"
                           name="notify" value={userProfile.content.notifications.phone.notify}
                           checked={userProfile.content.notifications.phone.notify}
                           onChange={(e) => toggleReceiveNotifications("phone", 'checkbox', e)}/>
                    <span className="form-check-label">Phone (SMS)</span>
                </div>

                {userProfile.content.notifications.phone.notify &&
                    <div className="ms-2 mb-3">
                        <TextInput
                            name="recipient"
                            value={userProfile.content.notifications.phone.recipient}
                            onChange={(e) => toggleReceiveNotifications("phone", 'string', e)}
                            placeholder="Enter phone no"
                            className="w-30"
                        />
                    </div>
                }

                <div className="form-check form-switch mb-2">
                    <input type="checkbox" className="form-check-input align-middle"
                           name="notify" value={userProfile.content.notifications.email_address.notify}
                           checked={userProfile.content.notifications.email_address.notify}
                           onChange={(e) => toggleReceiveNotifications("email_address", 'checkbox', e)}/>
                    <span className="form-check-label">Email address</span>
                </div>

                {userProfile.content.notifications.email_address.notify &&
                    <div className="ms-2 mb-4">
                        <TextInput
                            name="recipient"
                            value={userProfile.content.notifications.email_address.recipient}
                            onChange={(e) => toggleReceiveNotifications("email_address", 'string', e)}
                            placeholder="Enter email address"
                            className="w-30"
                        />
                    </div>
                }
            </div>

            {(userProfile.content.notifications.phone.notify == true || userProfile.content.notifications.email_address.notify == true) &&
                <div className="mt-5 ms-3">
                    <h4 className="lead fs-5 mb-2">Notify me when a:</h4>
                    <div className="ms-2">
                        <div className="form-check form-switch mb-2">
                            <input type="checkbox" className="form-check-input align-middle"
                                   name="notify_on_job" value={userProfile.content.notifications.notify_on_job}
                                   checked={userProfile.content.notifications.notify_on_job}
                                   onChange={(e) => toggleNotifications(e)}/>
                            <span className="form-check-label">Job has been listed</span>
                        </div>

                        <div className="form-check form-switch mb-2">
                            <input type="checkbox" className="form-check-input align-middle"
                                   name="notify_on_bid" value={userProfile.content.notifications.notify_on_bid}
                                   checked={userProfile.content.notifications.notify_on_bid}
                                   onChange={(e) => toggleNotifications(e)}/>
                            <span className="form-check-label">Bid has been made on a task</span>
                        </div>

                        <div className="form-check form-switch mb-2">
                            <input type="checkbox" className="form-check-input align-middle"
                                   name="notify_last_day" value={userProfile.content.notifications.notify_last_day}
                                   checked={userProfile.content.notifications.notify_last_day}
                                   onChange={(e) => toggleNotifications(e)}/>
                            <span className="form-check-label">Job has 24 hours remaining to bid</span>
                        </div>

                        <div className="form-check form-switch mb-2">
                            <input type="checkbox" className="form-check-input align-middle"
                                   name="notify_last_hour" value={userProfile.content.notifications.notify_last_hour}
                                   checked={userProfile.content.notifications.notify_last_hour}
                                   onChange={(e) => toggleNotifications(e)}/>
                            <span className="form-check-label">Job has 1 hour remaining to bid</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default NotificationSettings;