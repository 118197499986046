import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import UserDetails from "../../../modals/UserDetails";

import {setModal} from "../../../../utils/slices/modal.slice";
import {setToast} from "../../../../utils/slices/toasts.slice";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import {setUserProfile} from "../../../../utils/slices/userProfile.slice";
import {getRoleById, getRoleIconById, getStatusById} from "../../../../utils/helpers/app.helpers";
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";
import Loader from "../../../common/Loader";

const Users = () => {
    const dispatch = useDispatch();
    const toasts = useSelector(state => state.toasts);
    const userProfile = useSelector(state => state.userProfile);
    const [init, setInit] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        let url = API_ENDPOINT + "v1/Users/getUsers";
        let params = {};

        postAuthenticated(url, params).then(results => {
            dispatch(setUserProfile((results.data).find(x => x.id == userProfile.id)));

            setUsers(results.data);
            setInit(true);
        });
    }

    function verifyUser(event, user) {
        event.stopPropagation();
        let url = API_ENDPOINT + "v1/Users/verifyUser";
        let params = {
            user: user
        };

        postAuthenticated(url, params).then(results => {
            getUsers();

            let toast = {
                ...toasts,
                [toasts.length]: {
                    head: "Notification",
                    body: <span>User was successfully verified</span>,
                }
            };
            dispatch(setToast(Object.assign([], toast)));
        });
    }

    function launchUserModal(event, user) {
        event.stopPropagation();
        let modalSettings = {
            config: {
                component: UserDetails,
                props: {
                    user: user
                }
            },
            onClose: () => getUsers()
        }
        dispatch(setModal(modalSettings));
    }

    return (
        init ?
        <div className="mt-2">
            <div className="fs-14 text-secondary mb-2">Results: {users.length}</div>
            <table className="table user-table">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Role</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Status</th>
                        <th scope="col text-center">Actions</th>
                    </tr>
                </thead>

                <tbody className="">
                    {users.map((user, index) =>
                        <tr key={index} role="button" className="align-middle" onClick={(e) => launchUserModal(e, user)}>
                            <td>{getRoleIconById(user.role_id)} {getRoleById(user.role_id)}</td>
                            <td>{user.content.company_name}</td>
                            <td>{user.content.first_name} {user.content.last_name}</td>
                            <td>{user.email_address}</td>
                            <td>{getStatusById(user.status_id)}</td>
                            <td className="text-center">
                                {user.status_id == 2
                                    ? <button className="btn-theme darkblue text-white p-0 px-3 py-1" onClick={(e) => verifyUser(e, user)}>Verify</button>
                                    : <span role="button" className="fa fa-cog rebecca-purple px-2 py-1 fs-4 rounded" onClick={(e) => launchUserModal(e, user)} />
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        : <Loader />
    );
};

export default Users;