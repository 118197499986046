import {createSlice} from "@reduxjs/toolkit";

const modal = createSlice({
    name: "modal",
    initialState: {},
    reducers: {
        setModal: (state, {payload}) => {
            return {
                ...state,
                ...payload
            };
        },
        closeModal: (state, {payload}) => {
            return {}
        }
    }
})

export const {setModal, closeModal} = modal.actions;
export default modal.reducer;