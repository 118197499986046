import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useLocation} from "react-router-dom";

import skipbinco_logo from "../../assets/images/SkipBinCo-Logo.webp";
import "./../../assets/styles/header.css";

import Banner from "./Banner";
import {setBanner} from "../../utils/slices/banner.slice";
import {logoutUserProfile} from "../../utils/slices/userProfile.slice";
import {setNavigation} from "../../utils/slices/navigation.slice";
import {AUTHENTICATED_HEADER_NAVIGATION, UNAUTHENTICATED_HEADER_NAVIGATION} from "../../utils/structures/navigation.structures";

const Header = () => {
    const ACCEPTED_PATHS_WITH_HEADERS = ['/login', '/signup', '/listings', '/account'];
    const ACCEPTED_PATH_STRING_WITH_HEADERS = '/job/';
    const UNACCEPTED_PATH_WITH_HEADERS = ['/enquiry'];
    const UNACCEPTED_PATH_STRING_WITH_HEADERS = '/';

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const navigation = useSelector(state => state.navigation);
    const userProfile = useSelector(state => state.userProfile);

    useEffect(() => {
        if(navigation.header.link && navigation.header.label) {
            dispatch(setBanner({
                hide: false,
                label: navigation.header.label
            }));
        }
    }, [navigation]);

    function handleNavigation(nav) {
        if(nav.onClickFunc) {
            eval(nav.onClickFunc)();
        }else{
            dispatch(setNavigation({
                ...navigation,
                header: nav,
            }));
            document.title = `${nav.label} - Skipbinco`
        }
    }

    function logoutUser() {
        dispatch(logoutUserProfile());
    }

    function conditionalHeader() {
        if(ACCEPTED_PATHS_WITH_HEADERS.includes(location.pathname)) {
            if( (location.pathname == "/login" || location.pathname == "/signup") && userProfile && userProfile.id) {
                return false;
            }else{
                return true;
            }
        }

        if(UNACCEPTED_PATH_WITH_HEADERS.includes(location.pathname)) {
            return false;
        }

        if(location.pathname.includes(ACCEPTED_PATH_STRING_WITH_HEADERS)) {
            return true;
        }

        if(location.pathname.includes(UNACCEPTED_PATH_STRING_WITH_HEADERS) || (location.pathname.includes(UNACCEPTED_PATH_STRING_WITH_HEADERS) && location.pathname.length > 1)) {
            return false;
        }

        return true;
    }

    return (
        conditionalHeader() &&
        <div>
            <div className="w-75 m-auto">
                <div className="d-block d-md-flex mt-4">
                    <div className="col-md-6 col-12 mb-md-0 mb-3" style={{textAlign: "-webkit-center"}}>
                        <a href="/">
                            <img src={skipbinco_logo} width={130} height={70} alt="logo" />
                        </a>
                    </div>
                    <div className="d-flex col-12 col-md-6 align-self-center justify-content-center justify-content-md-end text-md-end fs-5 ms-3">
                        <span className="p-2 nav-link text-decoration-underline text-warning-emphasis fs-18 ps-3 pe-3 py-1 ms-1">{userProfile && userProfile.id ? `Hi ${userProfile.content.first_name}!` : 'Welcome!'}</span>
                        {userProfile && userProfile.id > 0 ?
                            AUTHENTICATED_HEADER_NAVIGATION.map((nav, index) =>
                                !nav.sub_nav ?
                                    <Link key={index} to={nav.onClickFunc ? null : nav.link} className={`nav-link hover-orange ${navigation.header.constant == nav.constant ? 'active' : ''} fs-18 ps-3 pe-3 py-1 ms-1`} onClick={() => handleNavigation(nav)}>
                                        {nav.label}
                                    </Link>
                                :
                                    <div className="dropdown">
                                        <li className={`nav-link fs-18`} role="navigationSlice">Profile</li>
                                        <div className="dropdown-content">
                                            {nav.sub_nav.map((sub, index) =>
                                                <Link key={index} to={sub.link} className={`nav-link text-start`}>
                                                    {sub.label}
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                            )
                        :
                            UNAUTHENTICATED_HEADER_NAVIGATION.map((nav, index) =>
                                <Link key={index} to={nav.onClickFunc ? null : nav.link} className={`nav-link hover-orange ${navigation.header.constant == nav.constant ? 'active' : ''} fs-18 ps-3 pe-3 py-1 ms-1`} onClick={() => handleNavigation(nav)}>
                                    {nav.label}
                                </Link>
                            )
                        }
                    </div>
                </div>
            </div>

            <Banner />
        </div>
    );
};

export default Header;