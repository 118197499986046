import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import Loader from "../../../common/Loader";
import ReviewForm from "../../../modals/ReviewForm";
import Confirmation from "../../../common/Confirmation";

import {setToast} from "../../../../utils/slices/toasts.slice";
import {setModal} from "../../../../utils/slices/modal.slice";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";
import {JOB_DETAILS_STRUCTURE} from "../../../../utils/structures/job.structures";
import {CONTRACTOR_ROLE_ID, CUSTOMER_ROLE_ID} from "../../../../utils/constants/roles.constants";

const Jobs = (props) => {
    const dispatch = useDispatch();
    const toasts = useSelector(state => state.toasts);
    const userProfile = useSelector(state => state.userProfile);

    const [jobs, setJobs] = useState(props.jobs || []);

    const [init, setInit] = useState(false);
    const [viewJobs, setViewJobs] = useState(true);
    const [selectedJob, setSelectedJob] = useState({});

    useEffect(() => {
        if(props.getCustomerJobs) {
            setJobs(props.jobs);
        }else{
            getContractorJobs();
        }
        setInit(true);
    }, [viewJobs]);

    function getContractorJobs() {
        let url = API_ENDPOINT + "v1/Jobs/getContractorJobs";
        let params = {
            user_id: userProfile.id
        };

        postAuthenticated(url, params).then(results => {
            setJobs(results.data);
        })
    }

    function updateCompletionStatus() {
        let url = API_ENDPOINT + "v1/Jobs/updateCompletionStatus";
        let params = {
            id: selectedJob.id
        }

        postAuthenticated(url, params).then(results => {
            let toast = {
                ...toasts,
                [toasts.length]: {
                    head: "Notification",
                    body: <span>Job status was successfully updated</span>,
                }
            };
            dispatch(setToast(Object.assign([], toast)));
        })
    }

    function launchUpdateCompletionStatusConfirmation(type) {
        let modalSettings = {
            config: {
                title: `Are you sure you would like to mark this job as ${type}? You cannot undo this change`,
                component: Confirmation,
                props: {
                    confirmMessage: "Confirm",
                    declineMessage: "Cancel",
                    onConfirm: () => {
                        updateCompletionStatus();
                        setViewJobs(true);
                    },
                    onDecline: () => {}
                }
            },
            onClose: () => {}
        }
        dispatch(setModal(modalSettings));
    }

    function launchReviewForm(event, job) {
        event.preventDefault();

        let modalSettings = {
            config: {
                component: ReviewForm,
                props: {
                    job: job
                }
            },
            onClose: () => {}
        };
        dispatch(setModal(modalSettings));
    }

    function handleJob(event, job) {
        event.preventDefault();

        setSelectedJob(job);
        setViewJobs(!viewJobs);
    }

    function determineCompletionStatus(completion_status, winning_bid_id) {
        if(userProfile.role_id == CONTRACTOR_ROLE_ID) {
            return completion_status == 0 || completion_status == 1 ? 'Attention required' :
                completion_status == 2 ? 'Pending on customer' : completion_status == 3 ? 'Job completed' : null;
        }else if(userProfile.role_id == CUSTOMER_ROLE_ID) {
            if(winning_bid_id != 0 && winning_bid_id != null) {
                return completion_status == 0 || completion_status == 1 ? 'Pending on provider' :
                    completion_status == 2 ? 'Attention required' : completion_status == 3 ? 'Job completed' : null;
            }else{
                return 'Open';
            }
        }
    }

    function determineCompletionColour(completion_status) {
        if(userProfile.role_id == CONTRACTOR_ROLE_ID) {
            return completion_status == 0 || completion_status == 1 ? 'text-danger' : completion_status == 2 ? 'text-warning' : completion_status == 3 ? 'text-success' : null
        }else if(userProfile.role_id == CUSTOMER_ROLE_ID) {
            return completion_status == 0 || completion_status == 1 ? 'text-warning' : completion_status == 2 ? 'text-danger' : completion_status == 3 ? 'text-success' : null
        }
    }

    return (
        init ?
            <div>
                {viewJobs &&
                    (jobs.length === 0 ?
                        <div className="lead fs-16 text-secondary mt-2">No jobs were found</div>
                    :
                        <React.Fragment>
                            <div className="fs-14 text-secondary mb-1">Results: {jobs.length}</div>
                            {jobs.map((job, index) =>
                                <div key={index} role="button" className="d-flex border border-1 mb-3 rounded" onClick={(e) => handleJob(e, job)}>
                                    <div className="col-9 p-3 ms-2 pt-1 pb-2">
                                        <span className={`p-0 m-0 fs-14 ${determineCompletionColour(job.completion_status)}`}>
                                            {determineCompletionStatus(job.completion_status, job.winning_bid_id)}
                                        </span>
                                        <div className="d-flex">
                                            <div className="col-6">
                                                <div className="lead fs-14 fw-normal">Name: {job.request.content.name}</div>
                                                <div className="text-secondary fs-14">Bid Amount: ${job.winning_bid.content.amount}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 align-self-center text-center pe-4">
                                        {userProfile.role_id == CUSTOMER_ROLE_ID &&
                                            job.review && job.review.id ?
                                                <div>
                                                    {[1,2,3,4,5].map(index =>
                                                        index < job.review.rating
                                                            ? <span className="fa fa-star checked text-warning"></span>
                                                            : <span className="fa fa-star text-light-emphasis"></span>
                                                    )}
                                                </div>
                                            : <button className="btn-theme darkpurple text-white fs-14 p-2 px-3" onClick={(e) => launchReviewForm(e, job)}>Leave a review</button>
                                        }
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )
                }

                {!viewJobs &&
                    <div>
                        <div role="button" className="text-link fs-14 link-primary p-0" onClick={(e) => handleJob(e, {})}>Go back</div>

                        <div className="mt-3 lead">
                            <div className="d-flex">
                                <div className="col-6">
                                    {JOB_DETAILS_STRUCTURE.map((detail, index) =>
                                        detail.constant === "bin_details" &&
                                        <React.Fragment key={index}>
                                            <span className="fs-16">{detail.label}</span>
                                            <hr className="m-0 p-0 mb-2 w-90" />

                                            <div className="mb-4">
                                                {detail.properties.map((property, property_index) =>
                                                    <div key={property_index} className="text-secondary fs-14 mb-1">
                                                        <i className={`${property.icon} pe-1 text-dark`} />
                                                        <span className="fw-bold">{property.label}:</span> {selectedJob.request.content[property.constant]}
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {JOB_DETAILS_STRUCTURE.map((detail, index) =>
                                        detail.constant === "location_details" &&
                                        <React.Fragment key={index}>
                                            <span className="fs-16">{detail.label}</span>
                                            <hr className="m-0 p-0 mb-2 w-90" />

                                            <div className="mb-4">
                                                {detail.properties.map((property, property_index) =>
                                                    <div key={property_index} className="text-secondary fs-14 mb-1">
                                                        <i className={`${property.icon} pe-1 text-dark`} />
                                                        <span className="fw-bold">{property.label}:</span> {selectedJob.content[property.constant]}
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="col-6">
                                    {JOB_DETAILS_STRUCTURE.map((detail, index) =>
                                        detail.constant === "delivery_details" &&
                                        <React.Fragment key={index}>
                                            <span className="fs-16">{detail.label}</span>
                                            <hr className="m-0 p-0 mb-2 w-100" />

                                            <div className="mb-4">
                                                {detail.properties.map((property, property_index) =>
                                                    <div key={property_index} className="text-secondary fs-14 mb-1">
                                                        <i className={`${property.icon} pe-1 text-dark`} />
                                                        <span className="fw-bold">{property.label}:</span> {selectedJob.request.content[property.constant]}
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}

                                    <div className="fs-16">Customer details</div>
                                    <hr className="m-0 p-0 mb-2 mt-1" />

                                    <div className="text-secondary fs-14 mb-1">
                                        <i className={`bi bi-person-fill pe-1 text-dark`} />
                                        <span className="fw-bold">Full name:</span> {selectedJob.content.first_name} {selectedJob.content.last_name}
                                    </div>

                                    <div className="text-secondary fs-14 mb-1">
                                        <i className={`bi bi-building-fill pe-1 text-dark`} />
                                        <span className="fw-bold">Company:</span> {selectedJob.content.company_name}
                                    </div>

                                    <div className="text-secondary fs-14 mb-1">
                                        <i className={`bi bi-telephone-fill pe-1 text-dark`} />
                                        <span className="fw-bold">Phone no:</span>
                                        <a className="ms-1 link-theme darkpurple" href={`tel:${selectedJob.content.phone}`}>{selectedJob.content.phone}</a>
                                    </div>

                                    <div className="text-secondary fs-14 mb-1">
                                        <i className={`bi bi-envelope-fill pe-1`} />
                                        <span className="fw-bold">Email address:</span>
                                        <a className="ms-1 link-theme darkpurple" href={`mailto:${selectedJob.content.email_address}`}>{selectedJob.content.email_address}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 mt-3">
                                <div className="mt-1">
                                    {userProfile.role_id == CONTRACTOR_ROLE_ID &&
                                        <React.Fragment>
                                            {selectedJob.completion_status == 0 &&
                                                <button className="w-50 m-auto btn-theme darkblue text-white fs-18" onClick={() => launchUpdateCompletionStatusConfirmation("delivered")}>
                                                    <i className="bi bi-send-fill me-1 fs-16" />
                                                    Mark as delivered
                                                </button> }

                                            {selectedJob.completion_status == 1 &&
                                                <button className="w-50 m-auto btn-theme darkblue text-white fs-18" onClick={() => launchUpdateCompletionStatusConfirmation("picked up")}>
                                                    <i className="bi bi-arrow-return-left border border-1 rounded-5 p-1 me-2 fs-16" />
                                                    Mark as picked up
                                                </button> }

                                            {selectedJob.completion_status == 2 &&
                                                <div className="rounded p-2 w-50">
                                                    Status:
                                                    <hr className="m-0 p-0 mb-2" />
                                                    <p className="fs-16 fst-italic">Waiting for customer to confirm completion of job</p>
                                                </div> }

                                            {selectedJob.completion_status == 3 &&
                                                <div className="rounded p-2 w-50">
                                                    Status:
                                                    <hr className="m-0 p-0 mb-2" />
                                                    <p className="fs-16 fst-italic">Job has been completed</p>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }

                                    {userProfile.role_id == CUSTOMER_ROLE_ID &&
                                        <React.Fragment>
                                            {selectedJob.completion_status == 2 ?
                                                <button className="w-50 m-auto btn-theme darkblue text-white fs-18" onClick={() => launchUpdateCompletionStatusConfirmation("picked up")}>
                                                    <i className="bi bi-send-fill me-1 fs-16" />
                                                    Confirm pickup
                                                </button>
                                            : selectedJob.completion_status == 0 || selectedJob.completion_status == 1 ?
                                                <div className="rounded p-2 w-50">
                                                    Status:
                                                    <hr className="m-0 p-0 mb-2" />
                                                    <p className="fs-16 fst-italic">Waiting for bin provider to confirm job</p>
                                                </div>
                                            : selectedJob.completion_status == 3 ?
                                                <div className="rounded p-2 w-50">
                                                    Status:
                                                    <hr className="m-0 p-0 mb-2" />
                                                    <p className="fs-16 fst-italic">Job has been completed</p>
                                                </div>
                                            : null}
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        : <Loader />
    );
};

export default Jobs;