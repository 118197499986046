import React, {useEffect, useState} from 'react';
import {DateTime} from "luxon";
import {API_ENDPOINT} from "../../utils/constants/app.constants";
import {postAuthenticated} from "../../utils/actions/post.actions";
import Loader from "../common/Loader";

const JobBids = (props) => {
    const job = props.job;
    const [init, setInit] = useState(false);
    const [bids, setBids] = useState([]);

    useEffect(() => {
        getAscendingBidsByJobId();
    }, []);

    function getAscendingBidsByJobId() {
        let url = API_ENDPOINT + "v1/Bids/getAscendingBidsByJobId";
        let params = {
            job_id: job.id
        };

        postAuthenticated(url, params).then(results => {
            setBids(results.data);
            setInit(true);
        })
    }

    function getInitials(contractor) {
        let string = `${contractor.first_name} ${contractor.last_name}`
        let names = string.split(' ');
        return `${names[0].substring(0, 1).toUpperCase()}.${names[1].substring(0, 1).toUpperCase()}`;
    }

    return (
        init ?
            <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                    <div className="modal-content w-70 h-70 m-auto">
                        <div className="modal-header p-3">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">List of bids</h3>
                            <button onClick={() => props.onClose()}
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <i className="bi bi-x-lg text-secondary-emphasis" />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className={`modal-body`} style={{overflowY: "scroll"}}>
                            <table className="table table-borderless w-90 m-auto">
                                <thead>
                                    <tr>
                                        <th>Contractor</th>
                                        <th>Time</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {bids.map((bid, index) =>
                                        <tr key={index}>
                                            <td>{getInitials(bid.contractor.content)}</td>
                                            <td>{DateTime.fromSQL(bid.created).toRelative({ unit: "" })} - {DateTime.fromSQL(bid.created).toFormat('dd/MM/yyyy hh:mm a')}</td>
                                            <td>AU ${bid.content.amount}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={() => props.onClose()}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        : <Loader />
    );
};

export default JobBids;