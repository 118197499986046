import $ from "jquery"
import {ADMIN_ROLE_ID, CONTRACTOR_ROLE_ID, CUSTOMER_ROLE_ID} from "../constants/roles.constants";

export function isObjectEmpty(obj) {
    return obj === null || obj === {} || obj === undefined || $.isEmptyObject(obj);
}

export function isEmpty(val) {
    return val === "" || val === undefined || val === null;
}

export function removeTags(str) {
    if ((str == null) || (str == ''))
        return false;
    else
        str = str.toString();

    return str.replace( /(<([^>]+)>)/ig, '');
}

export function getStatusById(status_id) {
    if(status_id == 1) {
        return <><span className="fa fa-circle lime-green me-2" />Active</>
    }else if(status_id == 2) {
        return <><span className="fa fa-circle bright-yellow me-2" />Pending</>
    }else if(status_id == 3) {
        return <><span className="fa fa-circle text-secondary me-2" />Archived</>
    }else if(status_id == 4) {
        return <><span className="fa fa-circle text-danger me-2" />Deleted</>
    }
}

export function getStatusColourById(status_id) {
    if(status_id == 1) {
        return <span className="fa fa-circle lime-green" />
    }else if(status_id == 2) {
        return <span className="fa fa-circle bright-yellow" />
    }else if(status_id == 3) {
        return <span className="fa fa-circle text-secondary" />
    }else if(status_id == 4) {
        return <span className="fa fa-circle text-danger" />
    }
}

export function getRoleById(role_id) {
    if(role_id == ADMIN_ROLE_ID) {
        return "Admin";
    }else if(role_id == CONTRACTOR_ROLE_ID) {
        return "Contractor";
    }else if(role_id == CUSTOMER_ROLE_ID) {
        return "Customer";
    }
}

export function getRoleIconById(role_id) {
    if(role_id == ADMIN_ROLE_ID) {
        return <span className="bi bi-person-fill" />;
    }else if(role_id == CONTRACTOR_ROLE_ID) {
        return <span className="bi bi-person-vcard-fill" />;
    }else if(role_id == CUSTOMER_ROLE_ID) {
        return <span className="bi bi-person-circle" />;
    }
}

export function extractListingDetails(listing_name) {
    return listing_name//.replace('Skip Bin', '');
}