import React from 'react';

const CheckboxInput = (props) => {
    return (
        <div className="">
            <input
                type="checkbox"
                id={props.id || props.name}
                name={props.name}
                value={props.value}
                checked={props.checked}
                disabled={props.disabled}
                onChange={props.onChange}
                className={`form-check-input ${props.className}`}
            />
            <label htmlFor={props.name} className={`me-4 ${props.labelClassName}`}>{props.label}</label>
        </div>
    );
};

export default CheckboxInput;