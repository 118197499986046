import React from 'react';
import Modal from "./components/common/Modal";
import Toast from "./components/common/Toast";

function App() {
    return (
        <React.Fragment>
            <Modal />
            <Toast />
        </React.Fragment>
    );
}

export default App;
