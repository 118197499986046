import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";

import Loader from "../../common/Loader";
import TextInput from "../../form/TextInput";

import {isEmpty} from "../../../utils/helpers/app.helpers";
import {postUnauthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {useSelector} from "react-redux";

const Enquiry = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const userProfile = useSelector(state => state.userProfile);
    const [job, setJob] = useState({});
    const [errors, setErrors] = useState({});
    const [view, setView] = useState("form");
    const [init, setInit] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const HAS_ENQUIRY = location.state && location.state.request && location.state.request.id;
    const states = ['Australian Capital Territory', 'New South Wales', 'Northern Territory', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia']

    useEffect(() => {
        HAS_ENQUIRY ? getJobByRequestId() : navigate('/');
    }, [HAS_ENQUIRY]);

    function getJobByRequestId() {
        let url = API_ENDPOINT + "v1/Jobs/getByRequestId";
        let params = {
            request_id: location.state.request.id
        };

        postUnauthenticated(url, params).then(results => {
            if(!results.data) {
                getDefaultJob();
            }else{
                setView('exists');
                setInit(true);
            }
        });
    }

    function getDefaultJob() {
        let url = API_ENDPOINT + "v1/Jobs/getDefaultJob";
        let params = {};

        postUnauthenticated(url, params).then(results => {
            let data = results.data;
            data.content.state = states[0];

            setJob(data);
            setInit(true);
        });
    }

    function createJob() {
        let url = API_ENDPOINT + "v1/Jobs/createJob";
        let params = {
            job: job,
            request_id: location.state.request.id
        };

        postUnauthenticated(url, params).then(results => {
            setView('success');
        });
    }

    function validateForm() {
        let _errors = {};
        let required_fields = ['first_name', 'last_name', 'address_1', 'suburb', 'state', 'postcode', 'phone_number', 'email_address'];

        required_fields.forEach((field) => {
            if(isEmpty(job.content[field])){ _errors[field] = "Field cannot empty"; }
        });

        setErrors(_errors);
        if(Object.keys(_errors).length === 0) {
            setHasSubmitted(true);
            createJob();
        }else{
            let firstErrorElement = document.getElementById(Object.keys(_errors)[0]);
            if(firstErrorElement) {
                firstErrorElement.scrollIntoView();
                firstErrorElement.focus();
            }
        }
    }

    function handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;

        setJob({...job,
            content: {
                ...job.content,
                [name]: value
            }
        });
    }

    return (
        init ?
            <div>
                {view === "exists" &&
                    <div className="w-75 m-auto pb-5 shadow shadow-md mt-5">
                        <div className="w-75 m-auto text-center heading-theme blue fs-42 fw-semibold py-3">Done!</div>
                        <div className="w-75 m-auto text-center fs-18 py-3 pt-1 text-theme">
                            It seems like this enquiry has already been made
                            <br/>
                            <div className="">
                                Please <Link onClick={() => navigate(-1)} className="link-primary text-decoration-underline">click here</Link> to go back, or <Link to={userProfile && userProfile.id ? '/account' : '/login'} className="link-primary text-decoration-underline">here</Link> to login
                            </div>
                        </div>
                    </div>
                }

                {view === 'success' &&
                    <div className="w-75 m-auto pb-5 shadow shadow-md mt-5">
                        <div className="w-75 m-auto text-center heading-theme blue fs-42 fw-semibold py-3">Thank you for using Skipbinco Auction Site!</div>
                        <div className="w-75 m-auto text-center fs-18 py-3 text-theme">
                            Your enquiry has been sent to our bin providers. Please allow 24hrs for the enquiry to take place, <br/>final prices will be available soon after.
                            <br/>
                            <br/>
                            <div className="">If you wish to track the progress of your enquiry, please <Link to={userProfile && userProfile.id ? '/account' : '/login'} className="link-primary text-decoration-underline">click here</Link>.</div>
                        </div>
                    </div>
                }

                {view === "form" ?
                    <React.Fragment>
                        <div id="form-title" className="w-75 m-auto text-center text-theme blue fs-36 fw-semibold py-1 pb-3">Place Your Enquiry</div>
                        <div className="d-flex w-75 m-auto pb-2 shadow shadow-md p-3">
                            <div className="w-100">
                                <div className="col-12 me-4">
                                    <div className="rounded p-4">
                                        <h3 className="lead fs-24 fw-semibold text-theme blue pb-3">Enquiry Details</h3>
                                        <div className="mb-3">
                                            <div className="d-flex">
                                                <div className="w-45">
                                                    <TextInput
                                                        name="first_name"
                                                        label="First Name"
                                                        value={job.content.first_name}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder="First Name"
                                                        className="bg-light rounded-0 p-3"
                                                        error={errors.first_name}
                                                        required={true}
                                                    />
                                                </div>

                                                <div className="w-45 ms-auto">
                                                    <TextInput
                                                        label="Last Name"
                                                        name="last_name"
                                                        value={job.content.last_name}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder="Last Name"
                                                        className="bg-light rounded-0 p-3"
                                                        error={errors.last_name}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <TextInput
                                                label="Company Name (optional)"
                                                name="company_name"
                                                value={job.content.company_name}
                                                onChange={(e) => handleChange(e)}
                                                placeholder="Company Name"
                                                className="bg-light rounded-0 p-3"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <TextInput
                                                label="Delivery Address"
                                                name="address_1"
                                                value={job.content.address_1}
                                                onChange={(e) => handleChange(e)}
                                                placeholder="House number and street name"
                                                className="bg-light rounded-0 p-3"
                                                error={errors.address_1}
                                                required={true}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <TextInput
                                                name="address_2"
                                                value={job.content.address_2}
                                                onChange={(e) => handleChange(e)}
                                                placeholder="Apartment, suite, unit, etc. (optional)"
                                                className="bg-light rounded-0 p-3"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <TextInput
                                                label="Suburb"
                                                name="suburb"
                                                value={job.content.suburb}
                                                onChange={(e) => handleChange(e)}
                                                placeholder=""
                                                className="bg-light rounded-0 p-3"
                                                error={errors.suburb}
                                                required={true}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label className={`lead form-label fs-16 p-0 m-0 mb-1`}>States</label><span className="text-danger"> *</span>
                                            <select name="state" className="form-control mb-3 bg-light rounded-0 p-3" onChange={(e) => handleChange(e)} required={true}>
                                                {states.map(state =>
                                                    <option value={state}>{state}</option>
                                                )}
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <TextInput
                                                label="Postcode"
                                                name="postcode"
                                                value={job.content.postcode}
                                                onChange={(e) => handleChange(e)}
                                                className="bg-light rounded-0 p-3"
                                                error={errors.postcode}
                                                required={true}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <TextInput
                                                label="Phone"
                                                name="phone_number"
                                                value={job.content.phone_number}
                                                onChange={(e) => handleChange(e)}
                                                placeholder="Phone"
                                                className="bg-light rounded-0 p-3"
                                                error={errors.phone_number}
                                                required={true}
                                            />
                                        </div>

                                        <div>
                                            <TextInput
                                                label="Email Address"
                                                name="email_address"
                                                value={job.content.email_address}
                                                onChange={(e) => handleChange(e)}
                                                placeholder="Email Address"
                                                className="bg-light rounded-0 p-3"
                                                error={errors.email_address}
                                                required={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="px-4">
                                        <TextInput
                                            label="Enquiry Notes"
                                            name="order_notes"
                                            value={job.content.order_notes}
                                            onChange={(e) => handleChange(e)}
                                            placeholder="Notes about your enquiry, e.g. special notes for delivery."
                                            className="bg-light rounded-0 p-3"
                                        />
                                    </div>

                                    <div className="mt-5 m-auto" style={{width: "97%"}}>
                                        <button className="btn-theme yellow rounded-0 w-100 py-3 fw-semibold fs-5" onClick={!hasSubmitted ? () => validateForm() : null} disabled={hasSubmitted}>
                                            {hasSubmitted
                                                ? <div><Loader /> Please allow 10-15 seconds</div>
                                                : "Post Enquiry"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                :null}
            </div>
        : <Loader />
    );
};

export default Enquiry;