import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import TextInput from "../form/TextInput";
import Loader from "../common/Loader";

import {setToast} from "../../utils/slices/toasts.slice";
import {postAuthenticated} from "../../utils/actions/post.actions";
import {getRoleIconById, getStatusColourById, isEmpty} from "../../utils/helpers/app.helpers";
import {API_ENDPOINT} from "../../utils/constants/app.constants";

const UserDetails = (props) => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(props.user);
    const toasts = useSelector(state => state.toasts);
    const [init, setInit] = useState(false);
    const [roles, setRoles] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [viewProfileDetails, setViewProfileDetails] = useState(true);
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState({
        password: "",
        confirm_password: ""
    });

    useEffect(() => {
        getRoles();
        getStatuses();

        setInit(true);
    }, []);

    function getRoles() {
        let url = API_ENDPOINT + "v1/Roles/getRoles";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setRoles(results.data);
        });
    }

    function getStatuses() {
        let url = API_ENDPOINT + "v1/Statuses/getStatuses";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setStatuses(results.data);
        });
    }

    function updateUser() {
        let url = API_ENDPOINT + "v1/Users/updateUser";
        let params = {
            user: user
        }

        postAuthenticated(url, params).then(results => {
            props.onClose();
        }).catch(error => alert(error))
    }

    function validatePassword() {
        let _errors = {};

        if(isEmpty(password.password)) _errors['password'] = "Field cannot be empty";
        if(isEmpty(password.confirm_password)) _errors['confirm_password'] = "Field cannot be empty";
        if((!isEmpty(password.password) && !isEmpty(password.confirm_password)) && password.password != password.confirm_password){
            _errors['password'] = "Passwords do not match";
            _errors['confirm_password'] = "Passwords do not match";
        }

        setErrors(_errors);
        if(Object.keys(_errors).length === 0) {
            changePassword();
        }
    }

    function changePassword() {
        let url = API_ENDPOINT + "v1/Users/changePassword";
        let params = {
            user_id: user.id,
            password: password.confirm_password
        }

        postAuthenticated(url, params).then(results => {
            let toast = {
                ...toasts,
                [toasts.length]: {
                    head: "Notification",
                    body: <span>Password was successfully changed</span>,
                }
            };
            dispatch(setToast(Object.assign([], toast)));
            props.onClose();
        })
    }

    function handlePassword(event) {
        setPassword({...password, [event.target.name]: event.target.value});
    }

    function handleUserContent(event) {
        let name = event.target.name;
        let value = event.target.value;

        let profile = {
            ...user,
            content: {
                ...user.content,
                [name]: value
            }
        }
        setUser(profile);
    }

    function handleUser(event) {
        let name = event.target.name;
        let value = event.target.value;

        let profile = {
            ...user,
            [name]: value
        }
        setUser(profile);
    }

    return (
        init ?
            <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                    <div className="modal-content w-70 h-70 m-auto">
                        <div className="modal-header d-block p-3">
                            <div className="d-flex">
                                <h3 className="text-xl font-semibold text-gray-900">{props.user.content.first_name} {props.user.content.last_name}</h3>
                                <button onClick={() => props.onClose()}
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <i className="bi bi-x-lg text-secondary-emphasis" />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <h4 className="lead fs-16 fst-italic pb-0 text-secondary">{props.user.content.company_name}</h4>
                        </div>

                        <div className={`modal-body m-auto w-95`} style={{overflowY: "scroll"}}>
                            <form>
                                {viewProfileDetails ?
                                    <div className="mx-auto">
                                        <div className="d-flex">
                                            <div className="col-6">
                                                <TextInput
                                                    name="first_name"
                                                    label="First Name"
                                                    value={user.content.first_name}
                                                    onChange={(e) => handleUserContent(e)}
                                                    placeholder="First name"
                                                    className="w-90"
                                                />
                                            </div>
                                            <div className="col-6">
                                                <TextInput
                                                    name="last_name"
                                                    label="Last Name"
                                                    value={user.content.last_name}
                                                    onChange={(e) => handleUserContent(e)}
                                                    placeholder="Last name"
                                                    className="w-90"
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <TextInput
                                                name="email_address"
                                                label="Email Address"
                                                value={user.email_address}
                                                onChange={(e) => handleUser(e)}
                                                placeholder="Email Address"
                                                className="w-95"
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <TextInput
                                                name="phone_number"
                                                label="Phone Number"
                                                value={user.content.phone_number}
                                                onChange={(e) => handleUserContent(e)}
                                                placeholder="Phone Number"
                                                className="w-95"
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <TextInput
                                                name="company_name"
                                                label="Company Name"
                                                value={user.content.company_name}
                                                onChange={(e) => handleUserContent(e)}
                                                placeholder="Company name"
                                                className="w-95"
                                            />
                                        </div>

                                        <div className="d-flex mt-3">
                                            <div className="col-6">
                                                <TextInput
                                                    name="abn"
                                                    label="ABN"
                                                    value={user.content.abn}
                                                    onChange={(e) => handleUserContent(e)}
                                                    placeholder="ABN"
                                                    className="w-90"
                                                />
                                            </div>

                                            <div className="col-6">
                                                <TextInput
                                                    name="acn"
                                                    label="ACN"
                                                    value={user.content.acn}
                                                    onChange={(e) => handleUserContent(e)}
                                                    placeholder="ACN"
                                                    className="w-90"
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <TextInput
                                                name="website"
                                                label="Website URL"
                                                value={user.content.website}
                                                onChange={(e) => handleUserContent(e)}
                                                placeholder="Website URL"
                                                className="w-95"
                                            />
                                        </div>

                                        <div className="w-95">
                                            <label className="mt-3 lead form-label fs-16 p-0 m-0 mb-1">{getRoleIconById(user.role_id)} - Role:</label>
                                            <select name="role_id" value={user.role_id} onChange={(e) => handleUser(e)} className={`form-control`} required={true}>
                                                {roles.map((role, index) =>
                                                    <option key={index} value={role.id}>{role.name}</option>
                                                )}
                                            </select>
                                        </div>

                                        <div className="w-95">
                                            <label className="mt-3 lead form-label fs-16 p-0 m-0 mb-1">{getStatusColourById(user.status_id)} - Status:</label>
                                            <select name="status_id" value={user.status_id} onChange={(e) => handleUser(e)} className={`form-control`} required={true}>
                                                {statuses.map((status, index) =>
                                                    <option key={index} value={status.id} defaultValue={user.status_id}>{status.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                :
                                    <div>
                                        <div className="d-flex">
                                            <div className="col-6">
                                                <TextInput
                                                    type="password"
                                                    name="password"
                                                    label="Password"
                                                    value={password.password}
                                                    onChange={(e) => handlePassword(e)}
                                                    placeholder="Password"
                                                    className="w-90"
                                                    error={errors.password}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <TextInput
                                                    type="password"
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    value={password.confirm_password}
                                                    onChange={(e) => handlePassword(e)}
                                                    placeholder="Confirm password"
                                                    className="w-90"
                                                    error={errors.confirm_password}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>

                        <div className="modal-footer d-flex flex-nowrap px-5">
                            <div className="col-6" onClick={() => setViewProfileDetails(!viewProfileDetails)}>
                                <button className="btn-theme darkblue text-white">{viewProfileDetails ? 'Change password' : 'View profile'}</button>
                            </div>
                            <div className="col-6 text-end">
                                {viewProfileDetails
                                    ? <button className="btn-theme green text-white" onClick={() => updateUser()} style={{width: "22%"}}>Update</button>
                                    : <button className={`btn-theme green text-white rounded`} onClick={() => validatePassword()}>Save Password</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <Loader />
    );
};

export default UserDetails;