import React from 'react';
import {useDispatch} from "react-redux";

import {logoutUserProfile} from "../../utils/slices/userProfile.slice";

const Exception = (props) => {
    const dispatch = useDispatch();
    const error = props.error;
    const method = error.responseJSON.method;
    const status = error.responseJSON.status;

    function loginMethod() {
        dispatch(logoutUserProfile({}));
        window.location.href = "/login";
    }

    function determineStatusCodeMessage() {
        if(status === 404) {
            return "Not Found";
        }else if(status === 500) {
            return "Internal Server Error";
        }else if(status === 400) {
            return "Bad Request";
        }else if(status === 401) {
            return "Unauthorised"
        }
    }

    return (
        <div>
            <div className="p-5 bg-danger">
                <div className="fs-18 text-white lead fw-semibold">Status:</div>
                <div className="fs-32 text-white lead">{status} {determineStatusCodeMessage()}</div>
                <hr className="text-white my-2" />
                <div className="fs-16 text-white lead fw-semibold">Message:</div>
                <div className="fs-18 text-white lead fst-italic">"{error.responseJSON.message}"</div>
            </div>

            <div className="px-5 pt-4">
                {method === "login" ?
                    <div role="button" className="mt-3 lead" onClick={() => loginMethod()}>
                        Click here to refresh
                    </div>
                    :null}

                {method === "contact" ?
                    <div className="mt-3 lead">
                        Please contact the admin for assistance
                    </div>
                    :null}
            </div>
        </div>
    )
};

export default Exception;