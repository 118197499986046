import React, {useEffect, useState} from 'react';
import CheckboxInput from "../../form/CheckboxInput";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {extractListingDetails} from "../../../utils/helpers/app.helpers";
import Loader from "../../common/Loader";

const Filters = (props) => {
    const [init, setInit] = useState(false);
    const [binSizes, setBinSizes] = useState([]);
    const [binTypes, setBinTypes] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
        getDistinctBinSizes();
        getDistinctBinTypes();
        getDistinctStates();
        setInit(true);
    }, []);

    function getDistinctBinSizes() {
        let url = API_ENDPOINT + "v1/Jobs/getDistinctBinSizes";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setBinSizes(results.data);
        });
    }

    function getDistinctBinTypes() {
        let url = API_ENDPOINT + "v1/Jobs/getDistinctBinTypes";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setBinTypes(results.data);
        });
    }

    function getDistinctStates() {
        let url = API_ENDPOINT + "v1/Jobs/getDistinctStates";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setStates(results.data);
        });
    }

    return (
        init ?
            <div className="">
                <div className="bg-light col-lg-10 col-md-12 border border-light-subtle rounded p-4 h-100 mb-2 m-md-auto position-relative">
                    <div className="mb-3">
                        <span className="fs-16 text-dark-emphasis fw-bold">Bin sizes:</span>
                        {binSizes.map((type, type_index) =>
                            <div key={type_index} className="">
                                <CheckboxInput
                                    name={type.bin_size}
                                    label={extractListingDetails(type.bin_size)}
                                    value={type.bin_size}
                                    onChange={(e) => props.handleFilters("bin_sizes", type.bin_size, e)}
                                    className="fs-14 align-text-bottom"
                                    labelClassName="ps-1 fs-14"
                                />
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <span className="fs-16 text-dark-emphasis fw-bold">Bin types:</span>
                        {binTypes.map((type, type_index) =>
                            <div key={type_index} className="">
                                <CheckboxInput
                                    name={type.bin_type}
                                    label={extractListingDetails(type.bin_type)}
                                    value={type.bin_type}
                                    onChange={(e) => props.handleFilters("bin_types", type.bin_type, e)}
                                    className="fs-14 align-text-bottom"
                                    labelClassName="ps-1 fs-14"
                                />
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <span className="fs-16 text-dark-emphasis fw-bold">States:</span>
                        {states.map((type, type_index) =>
                            <div key={type_index} className="">
                                <CheckboxInput
                                    name={type.state}
                                    label={type.state}
                                    value={type.state}
                                    onChange={(e) => props.handleFilters("states", type.bin_type, e)}
                                    className="fs-14 align-text-bottom"
                                    labelClassName="ps-1 fs-14"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        : <Loader />
    );
};

export default Filters;