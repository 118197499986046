import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {DateTime} from "luxon";

import Loader from "../../common/Loader";
import TextInput from "../../form/TextInput";
import Filters from "./Filters";

import {setBanner} from "../../../utils/slices/banner.slice";
import {setNavigation} from "../../../utils/slices/navigation.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {extractListingDetails, isEmpty} from "../../../utils/helpers/app.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {ADMIN_ROLE_ID, CONTRACTOR_ROLE_ID, CUSTOMER_ROLE_ID} from "../../../utils/constants/roles.constants";
import {AUTHENTICATED_HEADER_NAVIGATION, UNAUTHENTICATED_HEADER_NAVIGATION} from "../../../utils/structures/navigation.structures";

const Listings = () => {
    const dispatch = useDispatch();
    const banner = useSelector(state => state.banner);
    const navigation = useSelector(state => state.navigation);
    const userProfile = useSelector(state => state.userProfile);

    const [keywords, setKeywords] = useState("");
    const [init, setInit] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [filters, setFilters] = useState({
        sort_by: "desc",
        bin_sizes: [],
        bin_types: [],
        states: []
    });

    useEffect(() => {
        dispatch(setNavigation({
            ...navigation,
            hide: false,
            header: userProfile ? {...AUTHENTICATED_HEADER_NAVIGATION[0]} : {...UNAUTHENTICATED_HEADER_NAVIGATION[0]}
        }));
    }, []);

    useEffect(() => {
        dispatch(setBanner({
            ...banner,
            label: userProfile ? (userProfile.role_id == CUSTOMER_ROLE_ID ? 'My Orders' : 'Jobs') : 'Jobs'
        }));
    }, [navigation]);

    useEffect(() => {
        if(userProfile) {
            if(userProfile.role_id == CONTRACTOR_ROLE_ID || userProfile.role_id == ADMIN_ROLE_ID){
                getJobs();
            }
            if(userProfile.role_id == CUSTOMER_ROLE_ID) {
                getJobsByUserId();
            }
        }else{
            getJobs();
        }
    }, [filters, keywords]);

    function getJobs() {
        let url = API_ENDPOINT + "v1/Jobs/getJobs";
        let params = {
            keywords: keywords,
            filters: filters,
        };

        postAuthenticated(url, params).then(results => {
            setJobs(results.data);
            setInit(true);
        });
    }

    function getJobsByUserId() {
        let url = API_ENDPOINT + "v1/Jobs/getJobsByUserId";
        let params = {
            user_id: userProfile.id
        }

        postAuthenticated(url, params).then(results => {
            let orders = results.data.filter(x => x.winning_bid_id == null);
            setJobs(orders);
            setInit(true);
        });
    }

    function handleFilters(constant, type, event) {
        if(isEmpty(filters[constant].find(x => x == event.target.value))) {
            setFilters({...filters,
                [constant]: Object.assign([], {
                    ...filters[constant],
                    [filters[constant].length]: event.target.value
                })});
        }else{
            setFilters({...filters, [constant]: filters[constant].filter(function(item) {
                return item !== event.target.value
            })});
        }
    }

    function calculateRemainingTime(job) {
        let auction;
        const date_delivery = DateTime.fromFormat(job.request.content.delivery_date, "MMMM dd, yyyy");
        const created_timestamp = DateTime.fromSQL(job.created);
        let difference = created_timestamp.diff(date_delivery);

        // Within 20hrs, 12hr auction
        if(difference.toFormat('hh') <= 20) {
            auction = created_timestamp.plus({ hours: 12 });
        }

        // Greater than 20hrs, 20hr auction
        if(difference.toFormat('hh') > 20) {
            auction = created_timestamp.plus({ hours: 20 });
        }

        let auction_diff = auction.diffNow();
        return auction_diff.values.milliseconds > 0;
    }

    function determineStatus(job) {
        if(job.winning_bid_id != null) {
            return <span className="lead text-danger fs-14 fw-normal">Closed</span>
        }else if(job.winning_bid_id == null && !calculateRemainingTime(job)) {
            return <span className="lead text-danger fs-14 fw-normal">Closed</span>
        }else if(job.winning_bid_id == null && calculateRemainingTime(job)) {
            return <span className="lead  text-success fs-14 fw-normal">Open</span>
        }
    }

    return (
        init ?
            <div className="w-85 m-auto mt-4">
                <div className="">
                    <div className="d-flex d-lg-none">
                        <div className="col-12 col-lg-8 d-flex">
                            <div className="col-9">
                                <div className="fs-14 text-secondary">Results: {jobs.length}</div>
                                <TextInput
                                    name="keywords"
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                    placeholder="Search..."
                                    className="w-50 rounded mb-2 border-1 px-2 py-1"
                                />
                            </div>

                            <div className="col-3">
                                {jobs.length > 0 &&
                                    <React.Fragment>
                                        <label className="fs-14 text-secondary-emphasis">Sort by:</label>
                                        <select name="sort_by" className="form-control fs-14 text-secondary px-2 py-1 rounded" onChange={(e) => setFilters({...filters, [e.target.name]: e.target.value})}>
                                            {[{constant: "desc", label: 'Newest'}, {constant: "asc", label: 'Oldest'}].map(option =>
                                                <option key={option.constant} className="fs-14 text-secondary" value={option.constant} defaultValue={filters.sort_by == option.constant}>{option.label}</option>
                                            )}
                                        </select>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="d-lg-flex d-md-block">
                        <div className="col-12 col-lg-3 rounded me-3 h-100 mb-md-2">
                            <Filters handleFilters={handleFilters} />
                        </div>

                        <div className="col-lg-8 col-md-12">
                            <div className="d-none d-lg-flex">
                                <div className="col-12 d-flex">
                                    <div className="col-9">
                                        <div className="fs-14 text-secondary">Results: {jobs.length}</div>
                                        <TextInput
                                            name="keywords"
                                            value={keywords}
                                            onChange={(e) => setKeywords(e.target.value)}
                                            placeholder="Search..."
                                            className="w-50 rounded border-1 px-2 py-1"
                                        />
                                    </div>

                                    <div className="col-3">
                                        {jobs.length > 0 &&
                                            <React.Fragment>
                                                <label className="fs-14 text-secondary-emphasis">Sort by:</label>
                                                <select name="sort_by" className="form-control fs-14 text-secondary" onChange={(e) => setFilters({...filters, [e.target.name]: e.target.value})}>
                                                    {[{constant: "desc", label: 'Newest'}, {constant: "asc", label: 'Oldest'}].map(option =>
                                                        <option key={option.constant} className="fs-14 text-secondary" value={option.constant} defaultValue={filters.sort_by == option.constant}>{option.label}</option>
                                                    )}
                                                </select>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>

                            {init ?
                                jobs.length === 0 ?
                                    <div className="lead fs-16 ps-1 text-secondary">{userProfile && userProfile.role_id == CUSTOMER_ROLE_ID ? 'No orders were found' : 'No jobs were found'}</div>
                                    :
                                    jobs.map((job, index) =>
                                        <div key={index} className="border border-1 rounded p-3 mb-5">
                                            <div className="d-flex">
                                                <h3 className="col-lg-6 col-9 fs-20">{job.request.content.name} - {job.request.content.waste_type}</h3>
                                                <div className="col-lg-6 col-3 text-end">
                                                    {determineStatus(job)}
                                                </div>
                                            </div>
                                            <hr className="p-0 m-0 mb-3 mt-2" />
                                            <div className="d-lg-flex d-md-block">
                                                {/* Job details */}
                                                <div className="text-secondary fs-14 col-lg-3 col-md-12">
                                                    <i className="bi bi-geo-alt-fill pe-1 text-secondary" />
                                                    <span className="fw-bold">Suburb:</span> {job.content.suburb} {job.content.postcode}</div>

                                                <div className="text-secondary fs-14 col-lg-3 col-md-12 text-lg-center">
                                                    <i className="bi bi-trash2-fill pe-1 text-secondary" />
                                                    <span className="fw-bold">Bin type:</span> {job.request.content.waste_type}</div>

                                                <div className="text-secondary fs-14 col-lg-3 col-md-12 text-lg-center">
                                                    <i className="bi bi-arrows-angle-expand pe-2 text-dark" />
                                                    <span className="fw-bold">Bin size:</span> {extractListingDetails(job.request.content.name)}</div>

                                                <div className="text-secondary fs-14 col-lg-3 col-md-12 text-lg-end">
                                                    <i className="bi bi-calendar pe-1 text-dark" />
                                                    <span className="fw-bold">Delivery date:</span> {job.request.content.delivery_date}</div>
                                            </div>

                                            <div className="d-flex mt-4">
                                                <div className="text-start col-6">
                                                    <div className="lead fs-14 p-0 m-0 fw-bold">Requested:</div>
                                                    <span className="fs-14">{DateTime.fromSQL(job.created).toRelative({ unit: "" })} - {DateTime.fromSQL(job.created).toFormat('dd/MM/yyyy hh:mm a')}</span>
                                                </div>

                                                <div className="text-end col-6">
                                                    <Link to={`/job/${job.hash}`} state={{id: job.id}}>
                                                        <button className="btn-theme darkblue text-white w-40 mt-3 mb-1">
                                                            {job.winning_bid_id != null && 'View Bids'}
                                                            {job.winning_bid_id == null && !calculateRemainingTime(job) && 'View Bids'}
                                                            {job.winning_bid_id == null && calculateRemainingTime(job) && 'Place Bid'}
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                : <Loader /> }
                        </div>
                    </div>
                </div>
            </div>
        : <Loader />
    );
};

export default Listings;