import React from 'react';
import {useSelector} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Job from "./components/pages/job/Job";
import Header from "./components/common/Header";
import Welcome from "./components/pages/index/Welcome";
import Enquiry from "./components/pages/enquiry/Enquiry";
import Account from "./components/pages/account/Account";
import Login from "./components/pages/authenticate/Login";
import Signup from "./components/pages/authenticate/Signup";
import Listings from "./components/pages/listings/Listings";
import Onboarding from "./components/pages/onboarding/Onboarding";

const AppRoutes = () => {
    const navigation = useSelector(state => state.navigation);
    const userProfile = useSelector(state => state.userProfile);
    const IS_AUTHENTICATED = userProfile && userProfile.id ? true : false;

    return (
        <BrowserRouter>
            {!navigation.hide ? <Header /> : null}

            <Routes>
                <Route path="/enquiry" element={<Enquiry />} />
                <Route path="/listings" element={<Listings />} />

                <Route path="/job/:hash" element={<Job />} />
                <Route path="/" element={<Welcome />} />
                <Route path="/:request_hash" element={<Welcome />} />

                {IS_AUTHENTICATED ?
                    <React.Fragment>
                        <Route path="/onboarding" element={<Onboarding />} />
                        <Route path="/account" element={<Account />} />
                    </React.Fragment>
                :
                    <React.Fragment>
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup userType="customer" />} />
                        <Route path="/contractor" element={<Signup userType="contractor" />} />
                    </React.Fragment>
                }
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;