export const UNAUTHENTICATED_HEADER_NAVIGATION = [
    { constant: "jobs",
      label: "Jobs",
      link: "/listings"},

    { constant: "signup",
      label: "Signup",
      link: "/signup"},

    { constant: "login",
      label: "Login",
      link: "/login"},
];

export const PROFILE_SUB_NAVIGATION = [
    { parent_constant: "profile",
        constant: "dashboard",
        label: "My Dashboard",
        link: "/dashboard"},

    { parent_constant: "profile",
        constant: "account",
        label: "My Account",
        link: "/account"},

    { parent_constant: "profile",
        constant: "logout",
        label: "Logout",
        onClickFunc: "logoutUser"},
];

export const AUTHENTICATED_HEADER_NAVIGATION = [
    { constant: "jobs",
      label: "Jobs",
      link: "/listings"},

    { constant: "account",
      label: "Account",
      link: "/account"},

    { constant: "logout",
      label: "Logout",
      link: "",
      onClickFunc: "logoutUser"},
];
